import React from "react";
import { Container, Button } from "react-bootstrap";
import style from "./Exterior.module.css";
import UploadImgComponent from "./UploadImg.component";
import { EXTERIOR_STYLES } from "../../constants/data";
import { EXTERIOR_COLORS } from "../../constants/data";
import Select from "react-select";
import { useHistory } from "react-router-dom";

const SelectStyleComponent = ({
  onSelectStyle,
  onFileUpload,
  exteriorDetails,
  onGenerate,
  setExteriorDetails,
  loading,
}) => {
  const history = useHistory();

  const handleStyleChange = (style) => {
    onSelectStyle(style);
  };
  console.log(exteriorDetails.style);

  const imageUrl = exteriorDetails.file
    ? URL.createObjectURL(exteriorDetails.file)
    : "your-house-image.jpg";

  const groupedOptions = [
    {
      label: "Style",
      options: EXTERIOR_STYLES,
    },
    {
      label: "Color",
      options: EXTERIOR_COLORS,
    },
  ];

  const groupStyles = {
    group: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #ccc", // Add a bottom border to separate groups
    }),

    option: (provided, state) => ({
      ...provided,
      paddingLeft: "30px",
      backgroundColor: state.isSelected ? "#110e2e" : "white",
      color: state.isSelected ? "white" : provided.color,

      "&:hover": {
        backgroundColor: state.isSelected ? "#110e2e" : "lightblue",
      },
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "14px",
      textTransform: "Capitalize",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "0px",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "0px",
      height: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      height: "100%", // Set the max-height of the dropdown list
    }),
  };

  return (
    <Container className="mt-5 justify-content-center">
      <div
        className="position-absolute align-items-center d-flex backLink "
        style={{ marginLeft: "0px", cursor: "pointer" }}
        onClick={() => {
          if (exteriorDetails.step === "preview") {
            setExteriorDetails({ ...exteriorDetails, step: "upload" });
          } else {
            history.goBack();
          }
        }}
      >
        <svg
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          width={16}
          height={16}
        >
          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
        Back
      </div>
      <div className="row">
        <div className="  col-12 col-lg-8 px-3">
          {exteriorDetails.step === "upload" ? (
            <UploadImgComponent onFileUpload={onFileUpload} />
          ) : (
            <div>
              <h5 className="mb-2">Selected Image</h5>
              <div className="bg-white d-flex justify-content-center">
                <img
                  src={imageUrl}
                  alt="House"
                  className={`img-fluid ${style.uploadedImage}`}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-12 col-lg-4 mt-3 mt-sm-0">
          <div className={`d-flex ${style.exteriorStyle}`}>
            <div>
              <h5
                className={`mb-2 mt-sm-3 mt-lg-0 ${exteriorDetails.step === "preview" ? "mt-lg-4" : "mt-lg-0"
                  }`}
              >
                Choose Your Exterior Style
              </h5>
              <Select
                placeholder="Select Style"
                className={`${style.dropdownList}`}
                options={groupedOptions}
                styles={groupStyles}
                onChange={(selectedValue) => {
                  console.log(selectedValue.value);
                  handleStyleChange(selectedValue.value);
                }}
                value={
                  exteriorDetails.style
                    ? {
                      label: exteriorDetails.style,
                      value: exteriorDetails.style,
                    }
                    : null
                }
              />
            </div>
            <div className="py-4 py-sm-4 py-lg-0">
              <div className={style.DropdownButtonWrapper}>
                <h5 className="mb-2">Proceed with this image selection</h5>
                <Button
                  onClick={onGenerate}
                  disabled={
                    !exteriorDetails.style || !exteriorDetails.file || loading
                  }
                >
                  {loading ? "Generating..." : "Generate"}
                </Button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <p className="mt-2 col-lg-8 " style={{ fontSize: "14.4px", textAlign: "center" }}>For optimal results we recommend you use photos taken in good lighting with no obstructions in front of the home (cars, people, etc).</p>
    </Container>
  );
};

export default SelectStyleComponent;
