import React, { useEffect } from "react";
import classes from "./Home.module.css";
import entryDoorIcon from "../../assets/images/entrydoor__icon.svg";
import exteriorIcon from "../../assets/images/exterior_icon.svg";
import garageIcon from "../../assets/images/garage_icon.svg";
import { Link } from "react-router-dom";
import HeaderComponent from "../common/header/Header.component";
import BeforeAfterCarousel from "../beforeAfterCarousel/BeforeAfterCarousel.component";

const HomeComponent = ({
  resetCatelogData,
  resetSelectedCatalogDoor,
  selectedCatalogImageDimensions,
}) => {
  useEffect(() => {
    resetCatelogData && resetCatelogData();
    resetSelectedCatalogDoor && resetSelectedCatalogDoor();
  }, [resetCatelogData, resetSelectedCatalogDoor]);

  return (
    <>
      <HeaderComponent />
      <div className={classes.homeContainer}>
        <div className={classes.contentWrapper}>
          <h3 className={classes.title}>
            Visualize Your Dream Home <br />
            With Modif<span>AI</span>
          </h3>
        </div>
        <div className={classes.buttonWrapper}>
          <div>
            <Link to="/modifai/exterior">
              <button
                className={[classes.button, classes.exteriorButton].join(" ")}
              >
                <img src={exteriorIcon} alt="exterior icon modifAI" />
                <span>Exterior</span>
              </button>
            </Link>
            <p className={classes.buttonLabel}>Reimagine your Exterior</p>
          </div>
          <div>
            <Link to="/modifai/interior">
              <button
                className={[classes.button, classes.interiorButton].join(" ")}
              >
                <img src={exteriorIcon} alt="interior icon modifAI" />
                <span>Interior</span>
              </button>
            </Link>
            <p className={classes.buttonLabel}>Redesign your Interior</p>
          </div>
          <div>
            <Link to="/modifai/garage">
              <button
                className={[classes.button, classes.garageDoorButton].join(" ")}
              >
                <img src={garageIcon} alt="Garage Doors icon modifAI" />
                <span>Garage Doors</span>
              </button>
            </Link>
            <p className={classes.buttonLabel}>Try on New Garage Doors</p>
          </div>
          <div>
            <Link to="/modifai/frontdoor">
              <button
                className={[classes.button, classes.entryDoorButton].join(" ")}
              >
                <img src={entryDoorIcon} alt="Entry Doors icon modifAI" />
                <span>Entry Doors</span>
              </button>
            </Link>
            <p className={classes.buttonLabel}>Try on New Doors</p>
          </div>
        </div>
        <BeforeAfterCarousel />
      </div>
    </>
  );
};

export default HomeComponent;