import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import UploadIcon from '../../../assets/images/Upload.svg';
import { Title } from '../title/Title.component';
import { isMobileDevice, isIPadDevice } from '../../../utils';
import { ConfirmationModal } from '../modal/Modal.component';
import classes from './Input.module.css';
import convert from 'image-file-resize';
import { useDispatch } from 'react-redux';
import { setImageDimensions } from '../../../redux/common/actions';


const isIPad = isIPadDevice();
const isIpadPro = document.body.clientWidth > 992;

export const FileUpload = ({ setHouseImg, isLandscapeView, isMobileOS, isResponsiveView, isFrontDoor }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();

    const setFileImage = (imgFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgFile);
        reader.onloadend = function (e) {
            const image = new Image();
            image.src = e.target.result;
            image.onload = function (ev) {
                //    var canvas = document.getElementById('canvas');
                //    canvas.width = image.width;
                //    canvas.height = image.height;
                //    var ctx = canvas.getContext('2d');
                //    ctx.drawImage(image,0,0);
                const { name, type } = imgFile;
                setHouseImg(image.src, { name, type }, imgFile);
            }
        };
    }

    const resizeFile = (file, dimension) => {
        const newDimensions = handleResizingRatio(dimension);
        return new Promise((resolve) => {
            convert({
                file: file,
                width: newDimensions.x,
                height: newDimensions.y,
                type: 'jpeg'
            }).then(resp => resolve(resp))
        });
    }

    const handleFileChange = async (evt) => {
        try {
            const selectedFile = evt.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = function (e) {
                const image = new Image();
                image.src = e.target.result;
                image.onload = async function (ev) {
                    const imageFile = await resizeFile(selectedFile, { x: image.width, y: image.height });
                    setFileImage(imageFile);
                    evt.target.value = '';
                }
            };


        } catch (e) {
            // console.log('Error:', e);
        }
    }

    const handleResizingRatio = (dimensions) => {
        if (dimensions.x > dimensions.y) {
            const newDimensions = { x: 2048, y: Math.round((2048 * dimensions.y) / dimensions.x) }
            dispatch(setImageDimensions(newDimensions))
            return newDimensions
        } else {
            const newDimensions = { x: Math.round((dimensions.x * 2048) / dimensions.y), y: 2048 }
            dispatch(setImageDimensions(newDimensions))
            return newDimensions
        }
    }

    const handleUploadFile = () => {
        setIsModalVisible(true);
    }

    const handleClose = (val) => {
        setIsModalVisible(false);
        val && document.getElementById('file-upload').click();
    }

    const titleText = isResponsiveView ? 'Take a picture or upload a photo' : 'Upload your own house here';
    return (
        <>
            <ConfirmationModal show={isModalVisible} uploadFile={handleClose}
                message="Please use or take door pictures that are square on.  Door pictures that are taken at an angle or are not level in the photo will not be processed correctly."
                confirmBtn="Upload"
            />
            <div className={classes.inputFileContainer}>
                <Title title={titleText} isMobileOS={isMobileOS} />
                <label className={classes.customFileUpload} onClick={handleUploadFile}> {/*-- htmlFor="file-upload" -- */}
                    <img src={UploadIcon} className={`${isLandscapeView && classes.isLandscapeViewImg} ${!isMobileOS && classes.isDesktopViewImg} ${!isLandscapeView && isIPad && isIpadPro && classes.iPadProCls} ${isLandscapeView && isIPad && classes.ipadLandscape}`} />
                </label>
                <input id="file-upload" type="file" name="image"
                    multiple={false} accept="image/*" onChange={handleFileChange} /> {/** 
                capture="environment" */}
            </div>
        </>
    );
}

export const FormInput = ({ placeholder, ariaLabelValue, id, value = '', type = 'text', error = '', handleChange, handleKeyPress }) => {
    return (
        <InputGroup className={classes.inputContainer}>
            <FormControl
                placeholder={placeholder}
                aria-label={ariaLabelValue}
                id={id}
                type={type}
                value={value}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
            />
            {error && <div className={classes.inputError}>{error}</div>}
        </InputGroup>
    )
}