import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Stage, Layer, Image, Line, Group, Circle } from "react-konva";
import Perspective from "perspectivejs";
import ReactGa from "react-ga";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import Loader from "../common/loader/Loader.component";
import * as _ from "lodash";
import { EditAddDoorImageComponent } from "../common/modal/Modal.component";
import { PrimaryButton } from "../common/button/Button.component";
import classes from "./Edit.module.css";
import { getAddDoorData } from "../../utils";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const HouseImage = ({ imageSrc = null, houseDimensions, pathname }) => {
  const [houseImage, setHouseImage] = useState();

  useEffect(() => {
    imageSrc && setImage();
  }, [imageSrc]);

  const setImage = () => {
    const imageData = new window.Image();
    imageData.src = imageSrc;
    imageData.onload = ({ target: img }) => {
      const width = houseDimensions.x;
      const height = houseDimensions.y;
      img.width = width || img.width;
      img.height = height || img.height;
      setHouseImage(img);
    };
  };

  return <Image x={0} y={0} image={houseImage} />;
};

const PerspectiveCanvas = ({ polygonPoints, houseDimensions, stageRef }) => {
  const canvasRef = useRef(null);
  const [image, setImage] = useState(new window.Image());
  let interval = null;

  useEffect(() => {
    interval = setInterval(() => {
      canvasRef.current && getImage();
    }, 500);

    return () => clearInterval(interval);
  }, [canvasRef]);

  const getImage = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const points = [];
    const widthScale = houseDimensions.x / stageRef.current?.attrs?.width;
    const heightScale = houseDimensions.y / stageRef.current?.attrs?.height;
    polygonPoints.map((item) => {
      points.push([
        item[0] / widthScale + Number(stageRef.current?.attrs?.x || 0),
        item[1] / heightScale + Number(stageRef.current?.attrs?.y || 0),
      ]);
    });
    image.onload = () => {
      // context.drawImage(image, 100,100);
      const p = new Perspective(context, image);
      p.draw(points);
    };
    image.src =
      "https://flaskstorage.s3-us-west-2.amazonaws.com/uploads/Lucerne_White_Arched--2019-11-19-212533.png";
  };

  return <Image ref={canvasRef} draggable />;
};

const EditComponent = ({
  backStatus,
  selectedHouseImage,
  selectedCatalogImage,
  setSelectedCatalogDoor,
  selectedCatalogImageDimensions,
  setSelectedCatalogDoorDimensions,
  selectedCatalogImageData,
  setBackStatus,
  setBackBtnVisibility,
  setLoaderVisibility,
  setIsTransformationHappened,
  isTransformationHappened,
  setUpdateCatalogData,
  selectedDoorsDetails,
  houseDimensions,
  catalogOffset,
}) => {
  const stageRef = useRef(null);
  const [isDoorModalVisible, setIsDoorModalVisible] = useState(false);
  const [styleCSS, setStyleCSS] = useState({});
  const [updatedCatalogDoorDimensions, setUpdatedCatalogDoorDimensions] =
    useState({});
  const [selectedTransformer, setSelectedTransformer] = useState(null);
  const [
    localSelectedCatalogImageDimensions,
    setLocalSelectedCatalogImageDimensions,
  ] = useState([]);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [isStageDrag, setIsStageDrag] = useState(true);
  const [perspectiveDoorUI, setPerspectiveDoorUI] = useState([]);
  const [dragDiff, setDragDiff] = useState([]);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [defaultScale, setDefaultScale] = useState(1);
  const [focusing, setFocusing] = useState("");

  const history = useHistory();

  const scaleBy = 1.02;

  useEffect(() => {
    ReactGa.initialize(
      history.location.pathname === "/edit-garage"
        ? "UA-201220317-1"
        : "UA-201243744-1"
    );
    ReactGa.pageview(history.location.pathname + window.location.search);
  }, [history.location.pathname, window.location.search]);

  useEffect(() => {
    const points = [];
    const doorDragDiff = [];
    setBackBtnVisibility(true);
    setContainerDimensions();

    if (
      selectedCatalogImageData?.garageDoorData &&
      selectedCatalogImageData?.garageDoorData[0]?.coordinatesToPaint?.catalogID
    ) {
      setLocalSelectedCatalogImageDimensions(selectedCatalogImageDimensions);
      setUpdatedCatalogDoorDimensions(selectedCatalogImageData);
    }

    if (
      !_.isEmpty(
        selectedCatalogImageData?.garageDoorData &&
          selectedCatalogImageData?.garageDoorData[0]?.coordinatesToPaint
            ?.catalogID
      )
    ) {
      setLoaderVisibility(true);
    }
    selectedCatalogImageData?.garageDoorData?.map((item) => {
      points.push(item.coordinates);
      doorDragDiff.push([0, 0]);
    });
    setPolygonPoints(points);
    setDragDiff(doorDragDiff);

    setDefaultScale({
      x: stageRef.current.attrs.scaleX,
      y: stageRef.current.attrs.scaleY,
    });
    return () => stageRef?.current?.getStage().batchDraw();
  }, []);

  useEffect(() => {
    if (backStatus) {
      history.goBack();
      setBackStatus(false);
    }
  }, [backStatus]);

  const setContainerDimensions = () => {
    const stageWidth = houseDimensions.x || 640;
    const stageHeight = houseDimensions.y || 480;
    // now we need to fit stage into parent
    const containerWidth = isMobile || isTablet ? window.innerWidth - 30 : 640;
    const containerHeight =
      isMobile || isTablet ? window.innerWidth / 1.5 : 480;

    // // to do this we need to scale the stage
    const widthScale = containerWidth / stageWidth;
    const heightScale = containerHeight / stageHeight;

    stageRef.current.width(stageWidth * widthScale);
    stageRef.current.height(stageHeight * heightScale);
    stageRef.current.scale({ x: widthScale, y: heightScale });
    stageRef.current.draw();

    setStyleCSS({
      width: stageWidth * widthScale,
    });
  };

  const handleAddClick = () => {
    setIsDoorModalVisible(true);
  };

  const handleClose = (data) => {
    setIsDoorModalVisible(false);
    if (data) {
      const { doorType, doorStyle, imagePath } = data;
      !selectedCatalogImage && setSelectedCatalogDoor(data);
      const newLocalSelectedCatalogImageDimensions =
        localSelectedCatalogImageDimensions;
      const newUpdatedCatalogDoorDimensions =
        updatedCatalogDoorDimensions.garageDoorData
          ? updatedCatalogDoorDimensions
          : { garageDoorData: [] };
      // const item = getAddDoorData(doorType, SINGLE_DOOR, SINGLE_DOOR_GARAGE, imagePath, newLocalSelectedCatalogImageDimensions.length, selectedCatalogImage);
      const item = getAddDoorData(doorType, doorStyle, selectedDoorsDetails);
      newUpdatedCatalogDoorDimensions.garageDoorData.push(item);
      newLocalSelectedCatalogImageDimensions.push(item.coordinatesToPaint);
      !selectedCatalogImage && setSelectedCatalogDoor(data);

      setUpdatedCatalogDoorDimensions(newUpdatedCatalogDoorDimensions);
      setLocalSelectedCatalogImageDimensions(
        newLocalSelectedCatalogImageDimensions
      );

      const newPolygonPoints = [...polygonPoints];
      newPolygonPoints.push(item.coordinates);
      setPolygonPoints(newPolygonPoints);
      setDragDiff([...dragDiff, [0, 0]]);
    }
  };

  function isTouchEnabled() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  const zoomStage = (event) => {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  };

  const setZoomState = (e) => {
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const newScale = e ? oldScale * scaleBy : oldScale / scaleBy;
    setStageScale(newScale);
  };

  const handleDeleteSelected = () => {
    const { garageDoorData, shapeType } = selectedCatalogImageData;
    const garageDoorDataCopy = _.cloneDeep(garageDoorData);
    const polygonPointsCopy = _.cloneDeep(polygonPoints);
    const dragDiffCopy = _.cloneDeep(dragDiff);
    const updateGarageDoorData = garageDoorDataCopy.filter(
      (item, index) => index !== Number(focusing)
    );
    const updatePolygonPoints = polygonPointsCopy.filter(
      (item, index) => index !== Number(focusing)
    );
    const updateDragDiff = dragDiffCopy.filter(
      (item, index) => index !== Number(focusing)
    );
    setPolygonPoints(updatePolygonPoints);
    setDragDiff(updateDragDiff);
    setFocusing("");
    setUpdatedCatalogDoorDimensions({
      garageDoorData: updateGarageDoorData,
      shapeType,
    });

    // if (!updatedCatalogDoorDimensions.garageDoorData) {

    //   setUpdatedCatalogDoorDimensions({
    //     garageDoorData: updateGarageDoorData,
    //     shapeType,
    //   });
    // } else {
    //   const { garageDoorData, shapeType } = updatedCatalogDoorDimensions;
    //   const garageDoorDataCopy = _.cloneDeep(garageDoorData);

    //   setUpdatedCatalogDoorDimensions({
    //     garageDoorData: updateGarageDoorData,
    //     shapeType,
    //   });
    // }

    // const filterSelectedCatalogList =
    //   localSelectedCatalogImageDimensions.filter(
    //     (item, index) => index !== Number(focusing)
    //   );
    // setLocalSelectedCatalogImageDimensions([]);
    setSelectedTransformer(null);
    setIsTransformationHappened(true);
    setLocalSelectedCatalogImageDimensions(updateGarageDoorData);
  };

  const handleDoneClickFinal = () => {
    const garageDoorData = updatedCatalogDoorDimensions.garageDoorData;
    const shapeType = selectedCatalogImageData.shapeType;
    const garageDoorDataCopy = _.cloneDeep(garageDoorData);
    // garageDoorDataCopy[0].coordinates = [polygonPoints];
    garageDoorDataCopy.map((item, index) => {
      // dragDiff
      polygonPoints[index][0].map((item) => {
        item[0] = item[0] + dragDiff[index][0];
        item[1] = item[1] + dragDiff[index][1];
      });
      item.coordinates = polygonPoints[index];
    });
    setSelectedCatalogDoorDimensions({
      garageDoorData: garageDoorDataCopy,
      shapeType,
    });
    history.goBack();
    return;
  };

  useEffect(() => {
    const newPerspectiveDoorUI = [];
    polygonPoints.forEach((polyItems, polyIndex) => {
      const lineUI = [];
      const verticesUI = [];
      const points = [];
      const doorUI = [];
      polyItems[0].forEach((item, index) => {
        points.push(item[0]);
        points.push(item[1]);
        verticesUI.push(
          <Circle
            key={`${polyIndex}_${index}0`}
            x={item[0]}
            y={item[1]}
            name={`${item[0]}_${item[1]}`}
            radius={40}
            stroke={"#ccc"}
            shadowColor={"black"}
            shadowOffset={{ x: 2, y: 2 }}
            // fill={"white"}
            strokeWidth={5}
            draggable
            onDragMove={(e) => {
              const points = [...polygonPoints];
              points[polyIndex][0][index] = [
                Math.round(e.target.x()),
                Math.round(e.target.y()),
              ];
              setPolygonPoints(points);
            }}
            onMouseOver={(e) => {
              document.body.style.cursor = "move";
            }}
            onMouseLeave={(e) => {
              document.body.style.cursor = "default";
            }}
          />
        );

        verticesUI.push(
          <Circle
            key={`${polyIndex}_${index}1`}
            x={item[0]}
            y={item[1]}
            name={`${item[0]}_${item[1]}`}
            radius={32}
            stroke={"#ccc"}
            shadowColor={"black"}
            shadowOffset={{ x: 2, y: 2 }}
            // fill={"white"}
            strokeWidth={5}
            draggable
            onDragMove={(e) => {
              const points = [...polygonPoints];
              points[polyIndex][0][index] = [
                Math.round(e.target.x()),
                Math.round(e.target.y()),
              ];
              setPolygonPoints(points);
            }}
            onMouseOver={(e) => {
              document.body.style.cursor = "move";
            }}
            onMouseLeave={(e) => {
              document.body.style.cursor = "default";
            }}
          />
        );
      });

      lineUI.push(
        <Line
          key={polyIndex}
          name={`${polyIndex}`}
          points={points}
          closed={true}
          stroke={"#ccc"}
          shadowColor={"black"}
          shadowOffset={{ x: 2, y: 2 }}
          strokeWidth={5}
          onFocus={() => {}}
          onBlur={() => {}}
          fill={
            focusing === `${polyIndex}` ? `rgba(0,0,0,0.7)` : `rgba(0,0,0,0.5)`
          }
          onMouseDown={(e) => setFocusing(e.target.name())}
        />
      );
      // doorUI.push(
      //   <PerspectiveCanvas
      //   key={polyIndex}
      //   polygonPoints={polyItems[0]}
      //   houseDimensions={houseDimensions}
      //   stageRef={stageRef}
      // />
      // )
      newPerspectiveDoorUI.push(
        <Group
          name="polygonGroup"
          key={polyIndex}
          draggable
          onDragEnd={(e) => {
            if (e.target.name() === "polygonGroup") {
              const widthScale =
                houseDimensions.x / stageRef.current?.attrs?.width;
              const heightScale =
                houseDimensions.y / stageRef.current?.attrs?.height;
              const newDragDiff = [...dragDiff];
              const x = newDragDiff[polyIndex][0] + Number(e.target._lastPos.x);
              const y = newDragDiff[polyIndex][1] + Number(e.target._lastPos.y);
              newDragDiff[polyIndex] = [x * widthScale, y * heightScale];
              setDragDiff(newDragDiff);
            }
          }}
        >
          {lineUI}
          {verticesUI}
          {doorUI}
        </Group>
      );
    });
    setPerspectiveDoorUI(newPerspectiveDoorUI);
    setLoaderVisibility(false);
  }, [polygonPoints, focusing]);

  return (
    <>
      <Loader />
      <EditAddDoorImageComponent
        show={isDoorModalVisible}
        handleClose={handleClose}
        page={history?.location?.pathname}
      />
      <div className={`${classes.editContainer} container`}>
        <div
          className="position-absolute align-items-center d-flex previewBackLink text-white"
          style={{
            top: "30px",
            left: "0",
            marginLeft: "0px",
            cursor: "pointer",
          }}
          // onClick={() =>
          //   !loading &&
          //   setExteriorDetails({ ...exteriorDetails, step: "preview" })
          // }
        >
          <svg
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            width={16}
            height={16}
          >
            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
          Back
        </div>
        <TransformWrapper pan={{ disabled: isStageDrag }}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <div
              id="stage-parent"
              className={`${classes.dataContainer}`}
              style={styleCSS}
            >
              {/* <canvas ref={canvasRef}></canvas> */}
              {
                <div className={classes.zoomBtnContainer}>
                  <button onClick={zoomIn}>+</button>
                  <button onClick={zoomOut}>-</button>
                  <button onClick={resetTransform}>x</button>
                </div>
              }
              <TransformComponent>
                <Stage
                  ref={stageRef}
                  scaleX={stageScale === 1 ? defaultScale.x : stageScale}
                  scaleY={stageScale === 1 ? defaultScale.y : stageScale}
                  onDragEnd={(e) => {
                    const stage = stageRef.current;
                    setStageX(stage.x());
                    setStageY(stage.y());
                  }}
                  // For deskstop view
                  onMouseDown={(e) => {
                    setIsStageDrag(Boolean(e.target.name()));
                    setFocusing(e.target.name());
                  }}
                  // For mobile view
                  onTouchStart={(e) => {
                    setIsStageDrag(Boolean(e.target.name()));
                    setFocusing(e.target.name());
                  }}
                >
                  <Layer>
                    <HouseImage
                      imageSrc={selectedHouseImage}
                      houseDimensions={houseDimensions}
                      pathname={history.location.pathname}
                    />
                  </Layer>
                  <Layer>{perspectiveDoorUI}</Layer>
                </Stage>
              </TransformComponent>
              <div className={classes.optionsContainer} style={styleCSS}>
                <button
                  className={`${classes.addButton}`}
                  onClick={handleAddClick}
                >
                  Add
                </button>
                {focusing && Number(focusing) < polygonPoints.length && (
                  <PrimaryButton
                    title="Delete"
                    cssStyle={classes.button}
                    handleClick={handleDeleteSelected}
                  />
                )}
                <PrimaryButton
                  cssStyle={classes.addButton}
                  title="Done"
                  handleClick={handleDoneClickFinal}
                />{" "}
              </div>
            </div>
          )}
        </TransformWrapper>
      </div>
    </>
  );
};

export default EditComponent;
