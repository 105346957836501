import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import {
  isMobile,
  isTablet,
} from "react-device-detect";
import ReactGa from 'react-ga';
import { HOME_ITEMS_GARAGE, SINGLE_DOOR, DOUBLE_DOOR, SINGLE_DOOR_GARAGE, DOUBLE_DOOR_GARAGE } from "../../constants/data";
import {
  getCanvasImageData,
  getUploadedHouseImageData,
} from "../../utils";
import { uploadGarageHouseImage } from "../../services/Home.service";
import {
  getDoorImageForGarageType,
  getDeviceOS,
  setScreenOrientationChangeData,
} from "../../utils";
import {
  TabletView,
  MobileViewComponent,
  DesktopView,
} from "../common/device/Device.component";

const GarageComponent = (props) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [objectSrc, setObjectSrc] = useState(null);
  const [selectedCatalogName, setSelectedCatalogName] = useState("");
  const [objectCoordinates, setObjectCoordinates] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [garageDoorDraw, setGarageDoorDraw] = useState([]);
  const [isDeviceOSModalVisible, setIsDeviceOSModalVisible] = useState(false);
  const [shapeType, setShapeType] = useState(null);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [requestQuoteData, setRequestQuoteData] = useState({});
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [rotation, setRotation] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isInitialToaster, setIsInitialToaster] = useState(false);

  const {
    catalogData,
    selectedCatalogID,
    selectedTypeData,
    getCatalogData,
    setSelectedTypeID,
    selectedCatalogItemDetails,
    setLoaderVisibility,
    setToastrStatus,
    setScreenOrientationChange,
    getCatalogFilterData,
    sendEmail,
    setEditHouseImage,
    setSelectedCatalogDoor,
    setSelectedCatalogDoorDimensions,
    isResponsiveView,
    isMobileOS,
    isMobileLandscapeView,
    isLandscape,
    selectedHouseImage,
    selectedCatalogImage,
    selectedCatalogImageDimensions,
    selectedCatalogImageData,
    resetSelectedCatalogDoor,
    setBackBtnVisibility,
    isToastrVisible,
    setSelectedDoordDetails,
    selectedDoorsDetails,
    filterVariables,
    isSampleHouse,
    setIsSampleHouse,
    setting
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (setting.route === 'advantageoverhead') {
      ReactGa.initialize('G-RVYMR20Q3L')
      ReactGa.pageview(history.location.pathname + window.location.search)
    } else if (setting.route === 'carriagehouse') {
      ReactGa.initialize('G-VLPMGFDKCS')
      ReactGa.pageview(history.location.pathname + window.location.search)
    } else if (setting.route === 'modifai') {
      ReactGa.initialize('UA-201220317-1')
      ReactGa.pageview(history.location.pathname + window.location.search)
    }
  }, [history.location.pathname, window.location.search, setting])

  useEffect(() => {
    setBackBtnVisibility(false);
    const { one_car_garage, two_car_garage, front_door } = catalogData || {};

    if (!one_car_garage && !two_car_garage && !front_door) {
      setIsDeviceOSModalVisible(true)
      getCatalogData(setting?.garage?.catalog);
      setImgSrc(HOME_ITEMS_GARAGE[0].imgPath.default);
      setCoordinates(HOME_ITEMS_GARAGE[0].coordinates);
      setGarageDoorDrawData(HOME_ITEMS_GARAGE[0]);
      setEditHouseImage(HOME_ITEMS_GARAGE[0].imgPath.default);
      setIsSampleHouse(true);
      checkDevice();
      window.addEventListener("orientationchange", orientationChange);
    } else {
      setExistingData();
    };

    return () => {
      window.removeEventListener("orientationchange", orientationChange);
    };
  }, []);
  const setExistingData = () => {
    setImgSrc(HOME_ITEMS_GARAGE[0].imgPath.default);
    setCoordinates(HOME_ITEMS_GARAGE[0].coordinates);
    setGarageDoorDrawData(HOME_ITEMS_GARAGE[0]);
    setEditHouseImage(HOME_ITEMS_GARAGE[0].imgPath.default);
    setIsSampleHouse(true);
    checkDevice();
    setSelectedDoordDetails(catalogData?.one_car_garage[0]);
  };
  // const setExistingData = () => {
  //   // console.log('SelectedCatalogImage Data:', selectedCatalogImage, selectedCatalogImageData);
  //   const { garageDoorData, shapeType } = selectedCatalogImageData;
  //   const garageDoorDataCopy = _.cloneDeep(garageDoorData);
  //   setGarageDoorDraw([]);

  //   setTimeout(() => {
  //     setImgSrc(selectedHouseImage);
  //     setEditHouseImage(selectedHouseImage);
  //     setRotation(garageDoorDataCopy[0]?.coordinatesToPaint?.rotation);
  //     setGarageDoorDraw(garageDoorDataCopy);
  //     setShapeType(shapeType);
  //     setSelectedCatalogDoorDimensions({ garageDoorData, shapeType });
  //     setLoaderVisibility(false);
  //   }, 100);
  // };

  const orientationChange = () => {
    setLoaderVisibility(true);
    setTimeout(() => {
      const orientationChangeData = setScreenOrientationChangeData();
      setScreenOrientationChange(orientationChangeData);
      renderView();
      setLoaderVisibility(false);
    }, 1000);
  };

  const checkDevice = () => {
    const deviceOS = getDeviceOS();
    if (deviceOS === "ios") {
      setIsDeviceOSModalVisible(true);
      setIsIOSDevice(true);
    }
  };

  const handleSlideClick = (selected, garageDoorDrawData) => {
    const { imgPath, url_original, name, canvasDrawData, catalogID } = selected;
    setSelectedCatalogName(name);
    drawDoorForSelectedCatalog(imgPath || url_original, name, catalogID, garageDoorDrawData || garageDoorDraw);
    setObjectSrc(imgPath);
    setSelectedCatalogDoor(imgPath || url_original);
    setToastrStatus({
      visible: false,
      type: "",
      message: "",
    });
  };

  const handleHouseSlideClick = (selected) => {
    if (!filteredData.length) {
      const data = (selectedCatalogImageData?.garageDoorData && selectedCatalogImageData?.garageDoorData[0]?.labels.includes(SINGLE_DOOR_GARAGE) ?
        catalogData?.one_car_garage : catalogData.two_car_garage) || selectedTypeData
      setFilteredData(data)
    }
    if (imgSrc !== selected.imgPath) {
      setImgSrc(null);
      setGarageDoorDraw([]);
      setSelectedCatalogDoorDimensions({});
      // setLoaderVisibility(true);
      try {
        const {
          imgPath,
          coordinates,
          class_ids,
          classIDs,
          canvasDrawData,
          shapeType,
          rotation,
        } = selected;
        rotation && rotation[0] ? setRotation(rotation[0]) : setRotation(0);
        resetSelectedCatalogDoor();
        setIsSampleHouse(true);
        setImgSrc(imgPath);
        setObjectCoordinates({});
        setSelectedTypeID(classIDs || class_ids);
        setEditHouseImage(imgPath);
        setGarageDoorDrawData(selected);
        setObjectSrc(null);

        setTimeout(() => {
          setLoaderVisibility(false);
        }, 1000);
      } catch (err) {
        setSelectedTypeID([]);
        setLoaderVisibility(false);
      }
    }
  };

  const setGarageDoorDrawData = (selectedHouse) => {
    const doorData = selectedHouse.canvasDrawData.filter(
      (item) => item.classId === 1 || item.classId === 2
    );
    const filteredDoorData = doorData.filter(
      (item) =>
        item.labels.indexOf(SINGLE_DOOR) < 0 &&
        item.labels.indexOf(DOUBLE_DOOR) < 0
    );
    filteredDoorData &&
      filteredDoorData.forEach((item) => {
        item.imgPath = null;
      });
    filteredDoorData.map((item) => {
      if (item.coordinates.length === 1) {
        item.coordinates = item.coordinates
      } else {
        item.coordinates = [item.coordinates]
      }
    })
    setGarageDoorDraw(filteredDoorData);
    setShapeType(selectedHouse.shapeType);

    setSelectedCatalogDoorDimensions({
      garageDoorData: filteredDoorData,
      shapeType: selectedHouse.shapeType,
    });
  };

  const drawDoorForSelectedCatalog = (image, name, catalogID, garageDoorDraw) => {
    const garageDoorData = [...garageDoorDraw];
    garageDoorData &&
      garageDoorData.forEach((item, index) => {
        const imagePath =
          item.classId === selectedCatalogID
            ? image
            : getDoorImageForGarageType(catalogData, name, item.classId);
        const isArrayNeeded = _.get(item, "coordinates[0][0][0]");
        // const doorCoordinates = isSampleHouse ? item.coordinates : [item.coordinates];
        const doorCoordinates = isArrayNeeded
          ? item.coordinates
          : [item.coordinates];
        const coordinatesData = getCanvasImageData(doorCoordinates);

        if (item.coordinatesToPaint) {
          // item.coordinatesToPaint.catalogID = catalogID;
          item.coordinatesToPaint = item.coordinatesToPaint;
        } else {
          item.coordinatesToPaint = coordinatesData[0];
          item.coordinatesToPaint.catalogID = `${new Date().getTime()}${index}`;
        }
        // item.coordinatesToPaint = item.coordinatesToPaint ? item.coordinatesToPaint : coordinatesData[0];
        item.imgPath = imagePath || image;
      });

    setGarageDoorDraw(garageDoorData);
    setSelectedCatalogDoorDimensions({ garageDoorData, shapeType });
  };

  const handleSetFileImg = async (file, fileData, imgFile) => {
    setIsSampleHouse(false);
    setSelectedCatalogDoor(null);
    setImgSrc(null);
    setEditHouseImage(null);
    // setIsUpload(true);
    setImgData(fileData);
    setImgSrc(file);
    setEditHouseImage(file);
    handleIsUpload(imgFile);
  };

  const handleIsUpload = async (blobData) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = function (e) {
      const image = new Image();
      image.src = e.target.result;
      image.onload = function (ev) {
      }
    };

    setIsUpload(false);
    setLoaderVisibility(true);
    try {
      setSelectedTypeID([]);
      setRotation(null);
      resetSelectedCatalogDoor();
      const uploadResponse = await uploadGarageHouseImage(blobData);
      const coordinates = getUploadedHouseImageData(uploadResponse, 'garage');
      if (!coordinates.length) {
        setToastrStatus({
          visible: true,
          type: "error",
          message:
            "We were unable to detect a door in this image.  Please try again or go to edit and add a door manually",
        });
        setFilteredData([])
      }
      const rotationValue = _.get(uploadResponse, "data.rotation[0]", null);
      setRotation(rotationValue);
      setCoordinates(coordinates);
      setSelectedTypeIDList(uploadResponse);
      setLoaderVisibility(false);
    } catch (err) {
      // console.log('ERR:', err);
      setSelectedTypeID([]);
      setObjectCoordinates([]);
      setLoaderVisibility(false);
      setToastrStatus({
        visible: true,
        type: "error",
        message:
          "There was an error processing your image. Please try again or re-take the picture",
      });
    }
  };

  const setCoordinates = (coordinates) => {
    const canvasImageData = getCanvasImageData(coordinates);
    setObjectCoordinates(canvasImageData);
  };

  const setSelectedTypeIDList = (response) => {
    const doorList = _.get(response, "data.response", []);
    let shapeType = null;
    // const classIDs = _.get(response, 'data.response[0][0].class_ids' , []);

    const filterDoorList = doorList.filter(
      (item) =>
        item && (item[0].labels.includes(SINGLE_DOOR_GARAGE) ||
          item[0].labels.includes(DOUBLE_DOOR_GARAGE))
    );
    const classIDs = filterDoorList.map((item) => {
      return item[0]["classIDs"] || item[0]["class_ids"];
    });

    const canvasDrawData = filterDoorList.map((item, index) => {
      const classId = item[0].class_ids[0];
      const labels = item[0].labels;
      const coordinates = item[0].rois;
      const originalRotation = response?.data?.rotation[index] ? response?.data?.rotation[index] : response?.data?.rotation[0]
      const arcOuterCoordinates = item[0].roisouter;
      const arcInnerCoordinates = item[0].roisinner;
      item.canvasDrawData = [];
      item.canvasDrawData.push({ classId, labels, coordinates, originalRotation });
      return {
        classId,
        labels,
        coordinates,
        originalRotation,
        arcOuterCoordinates,
        arcInnerCoordinates,
      };
    });

    canvasDrawData &&
      canvasDrawData.forEach((item) => {
        const coordinateLength = _.get(item, "coordinates.length", 0);
        const { arcInnerCoordinates = [], arcOuterCoordinates = [] } = item;

        if (coordinateLength === 6) {
          shapeType = 1; // polygon detected
        } else if (arcInnerCoordinates.length && arcOuterCoordinates.length) {
          shapeType = 2; // arc detected
        } else {
          item.coordinates = [item.coordinates];
        }
      });

    setGarageDoorDrawData({ canvasDrawData, shapeType });
    classIDs.length && setSelectedTypeID(classIDs.flat());
    handleSlideClick(filteredData[0], canvasDrawData);
  };

  const handleFilterApply = (filters) => {
    getCatalogFilterData(filters, setting?.garage?.catalog);
  };

  const handleDeviceOSModalClick = () => {
    setIsDeviceOSModalVisible(false);
    setIsInitialToaster(true)
  };


  const handleToaster = (visible) => {
    setToastrStatus({
      visible,
      type: "",
      message: "",
    });
  };

  const handleSendEmail = (data) => {
    sendEmail(data, setting?.garage?.proposal);
  }

  useEffect(() => {
    const data = (selectedCatalogImageData?.garageDoorData && selectedCatalogImageData?.garageDoorData[0]?.labels.includes(SINGLE_DOOR_GARAGE) ?
      catalogData?.one_car_garage : catalogData.two_car_garage) || selectedTypeData

    if (!selectedCatalogImageData?.garageDoorData?.length) {
      setFilteredData([])
    } else {
      setFilteredData(data)
    }

    if (Object.keys(catalogData).length && !data?.length) {
      setToastrStatus({
        visible: true,
        type: "error",
        message:
          "Sorry there were no doors with these characteristics.  Please try other options or reset filters.",
      });
    }

    if (!selectedDoorsDetails || !Object.keys(selectedDoorsDetails).length) {
      catalogData?.one_car_garage?.length && setSelectedDoordDetails(catalogData?.one_car_garage[0])
    }
  }, [catalogData, selectedCatalogImageData])

  useEffect(() => {
    if (filteredData?.length && imgSrc && isSampleHouse) {
      handleSlideClick(filteredData[0]);
    }
  }, [imgSrc, filteredData])

  const renderView = () => {
    if (isTablet) {
      return (
        <TabletView
          houseData={HOME_ITEMS_GARAGE}
          catalogData={filteredData}
          imgSrc={imgSrc}
          objectSrc={objectSrc}
          selectedCatalogName={selectedCatalogName}
          objectCoordinates={objectCoordinates}
          isUpload={isUpload}
          imgData={imgData}
          garageDoorDraw={garageDoorDraw}
          isMobileLandscapeView={isMobileLandscapeView}
          isLandscape={isLandscape}
          isMobileOS={isMobileOS}
          isResponsiveView={isResponsiveView}
          isIOSDevice={isIOSDevice}
          handleIsUpload={handleIsUpload}
          handleSetFileImg={handleSetFileImg}
          handleHouseSlideClick={handleHouseSlideClick}
          handleSlideClick={handleSlideClick}
          handleFilterApply={handleFilterApply}
          isDeviceOSModalVisible={isDeviceOSModalVisible}
          shapeType={shapeType}
          handleDeviceOSModalClick={handleDeviceOSModalClick}
          setToastrStatus={setToastrStatus}
          getCatalogData={getCatalogData}
          sendEmail={handleSendEmail}
          setLoaderVisibility={setLoaderVisibility}
          selectedCatalogImage={selectedCatalogImage}
          rotation={rotation}
          setSelectedDoordDetails={setSelectedDoordDetails}
          selectedDoorsDetails={selectedDoorsDetails}
          filterVariables={filterVariables}
          isInitialToaster={isInitialToaster}
          setIsInitialToaster={setIsInitialToaster}
          setting={setting}
        />
      );
    } else if (isMobile) {
      return (
        <MobileViewComponent
          houseData={HOME_ITEMS_GARAGE}
          catalogData={filteredData}
          imgSrc={imgSrc}
          objectSrc={objectSrc}
          selectedCatalogName={selectedCatalogName}
          objectCoordinates={objectCoordinates}
          isUpload={isUpload}
          imgData={imgData}
          garageDoorDraw={garageDoorDraw}
          isMobileLandscapeView={isMobileLandscapeView}
          isMobileOS={isMobileOS}
          isResponsiveView={isResponsiveView}
          isIOSDevice={isIOSDevice}
          handleIsUpload={handleIsUpload}
          handleSetFileImg={handleSetFileImg}
          handleHouseSlideClick={handleHouseSlideClick}
          handleSlideClick={handleSlideClick}
          isDeviceOSModalVisible={isDeviceOSModalVisible}
          shapeType={shapeType}
          handleDeviceOSModalClick={handleDeviceOSModalClick}
          setToastrStatus={setToastrStatus}
          getCatalogData={getCatalogData}
          sendEmail={handleSendEmail}
          setLoaderVisibility={setLoaderVisibility}
          handleFilterApply={handleFilterApply}
          selectedCatalogImage={selectedCatalogImage}
          rotation={rotation}
          setSelectedDoordDetails={setSelectedDoordDetails}
          selectedDoorsDetails={selectedDoorsDetails}
          filterVariables={filterVariables}
          isInitialToaster={isInitialToaster}
          setIsInitialToaster={setIsInitialToaster}
          setting={setting}

        />
      );
    } else {

      return (
        <DesktopView
          houseData={HOME_ITEMS_GARAGE}
          catalogData={filteredData}
          imgSrc={imgSrc}
          objectSrc={objectSrc}
          selectedCatalogName={selectedCatalogName}
          objectCoordinates={objectCoordinates}
          isUpload={isUpload}
          imgData={imgData}
          garageDoorDraw={garageDoorDraw}
          isMobileLandscapeView={isMobileLandscapeView}
          isMobileOS={isMobileOS}
          isResponsiveView={isResponsiveView}
          isIOSDevice={isIOSDevice}
          handleIsUpload={handleIsUpload}
          handleSetFileImg={handleSetFileImg}
          handleHouseSlideClick={handleHouseSlideClick}
          handleSlideClick={handleSlideClick}
          isDeviceOSModalVisible={isDeviceOSModalVisible}
          shapeType={shapeType}
          handleDeviceOSModalClick={handleDeviceOSModalClick}
          setToastrStatus={setToastrStatus}
          getCatalogData={getCatalogData}
          sendEmail={handleSendEmail}
          setLoaderVisibility={setLoaderVisibility}
          handleFilterApply={handleFilterApply}
          selectedCatalogImage={selectedCatalogImage}
          rotation={rotation}
          setSelectedDoordDetails={setSelectedDoordDetails}
          selectedDoorsDetails={selectedDoorsDetails}
          filterVariables={filterVariables}
          isInitialToaster={isInitialToaster}
          setIsInitialToaster={setIsInitialToaster}
          setting={setting}

        />
      );
    }
  };

  return (
    <div onClick={() => isToastrVisible && handleToaster(false)}>
      {renderView()}
    </div>
  );
};

export default GarageComponent;