import React from 'react';
import classes from './Email.module.css';

const EmailComponent = ({houseImg, catalogImg, selectedCatalogName, data = {}}) => {

    const {firstName, lastName, email, phoneNumber, zip} = data;
    return (
        <div className={classes.emailContainer}>
            <div className={classes.emailHeader}>Product List</div>
            <div className={classes.emailCustomerHeader}>Customer Details</div>
            <div className={classes.emailItem}>
                First name: {firstName}
            </div>
            <div className={classes.emailItem}>
                Last name: {lastName}
            </div>
            <div className={classes.emailItem}>
                Email: {email}
            </div>
            <div className={classes.emailItem}>
                Phone: {phoneNumber}
            </div>
            <div className={classes.emailItem}>
                Zip: {zip}
            </div>
            <div className={classes.emailImgContainer}>
                {/* <canvas ref={canvasRef} /> */}
                <img src={houseImg} className={classes.emailImgHouse} />
            </div>
            <div className={classes.emailCatalogContainer}>
                {/* {isValidCatalogURL && <img src={catalogImg} className={classes.emailImgCatalog} />} */}
                <img src={catalogImg} className={classes.emailImgCatalog} />
                <div className={classes.emailTextCatalog}>{selectedCatalogName}</div>
                {/* {catalogImg && (
                    <canvas ref={catalogCanvasRef} />
                )} */}
            </div>
        </div>
    )
}

export default EmailComponent;