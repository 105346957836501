import React, { useEffect, useReducer, useRef, useState } from "react";
import "blueimp-canvas-to-blob";
import {
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  getCoordinatesToPaint,
  setRotationThresholdValue,
} from "../../../utils";
import classes from "./Canvas.module.css";
import { DOUBLE_DOOR, DOUBLE_DOOR_GARAGE } from "../../../constants/data";
import Perspective from 'perspectivejs';

const CanvasComponent = ({
  imageSrc = null,
  isUpload = false,
  imgData = {},
  shapeType,
  garageDoorDraw = [],
  isMobileOS,
  isResponsiveView,
  isMobileLandscapeView,
  handleIsUpload,
  rotation,
  selectedDoorsDetails,
  selectedCatalogImage,
  isFrontDoor
}) => {
  const propsRotation = rotation;
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const houseDimensions = useReducer(state => state.common.houseImageDimensions);

  useEffect(() => {
    imageSrc && drawImage(true, imageSrc);
  }, [imageSrc, garageDoorDraw]);

  useEffect(() => {
    if (garageDoorDraw && garageDoorDraw.length) {
      setLoader(true);
      paintDoorForSelectedCatalog(true);
    } else {
      setLoader(false);
    }
  }, [garageDoorDraw]);

  const paintDoorForSelectedCatalog = (isLoader) => {
    garageDoorDraw.forEach((item, index) => {
      const { xCoord, yCoord, width, height } = item.coordinatesToPaint || {};
      const doorImage = (item.labels.includes(DOUBLE_DOOR_GARAGE) || item.labels.includes(DOUBLE_DOOR) ?
        (selectedDoorsDetails?.double?.url_original || selectedDoorsDetails?.single?.url_original) :
        (selectedDoorsDetails?.single?.url_original || selectedDoorsDetails?.double?.url_original)) || selectedCatalogImage;

      if (shapeType === 2) {
        const { arcInnerCoordinates, arcOuterCoordinates } = item || {};
        if (arcInnerCoordinates && arcInnerCoordinates.length) {
          // setLoader(true);
          drawArcImage(
            false,
            doorImage,
            arcInnerCoordinates,
            arcOuterCoordinates
          );
        } else {
          setLoader(false);
        }
      }
      // else if (shapeType === 1) {
      //   const { coordinates } = item || {};
      //   if (width) {
      //     // setLoader(true);
      //     drawPolygonImage(
      //       false,
      //       doorImage,
      //       coordinates,
      //       xCoord,
      //       yCoord,
      //       width,
      //       height
      //     );
      //   } else {
      //     setLoader(false);
      //   }
      // } 
      else {
        if (width && (selectedCatalogImage || item.imgPath)) {
          setLoader(true);
          if (item?.coordinates[0]?.length === 6) {
            drawPolygonImage(
              false,
              doorImage,
              item.coordinates,
              xCoord,
              yCoord,
              width,
              height
            );
          } else {
            drawImage(
              false,
              doorImage,
              xCoord,
              yCoord,
              width,
              height,
              item.coordinates,
              item.coordinatesToPaint?.rotation
            );
          }
        }
        else {
          setLoader(false);
        }
      }
    });
  };

  const drawArcImage = (
    setDimension = false,
    imageSource,
    innerCoordinates,
    outerCoordinates
  ) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    !setDimension && image.setAttribute("crossOrigin", "anonymous");
    image.src = imageSource;

    image.onload = ({ target: img }) => {
      context.save();
      context.beginPath();
      // context.arc(294, 208, 47, 0, Math.PI, true);
      context.moveTo(innerCoordinates[0][0], innerCoordinates[0][1]);

      const xCoord = innerCoordinates[1][0] - innerCoordinates[0][0];
      const heightDifference = innerCoordinates[0][1] - outerCoordinates[0][1];
      context.arcTo(
        outerCoordinates[0][0] + xCoord / 2,
        outerCoordinates[0][1],
        innerCoordinates[1][0],
        innerCoordinates[1][1],
        (xCoord / 2) * 2
      );

      context.lineTo(innerCoordinates[1][0], innerCoordinates[1][1]);
      context.lineTo(innerCoordinates[2][0], innerCoordinates[2][1]);
      context.lineTo(innerCoordinates[3][0], innerCoordinates[3][1]);
      context.lineTo(innerCoordinates[0][0], innerCoordinates[0][1]);

      context.closePath();
      context.clip();
      context.fill();
      // context.globalCompositeOperation='source-atop';
      context.drawImage(
        image,
        innerCoordinates[0][0],
        outerCoordinates[0][1],
        innerCoordinates[1][0] - innerCoordinates[0][0],
        innerCoordinates[2][1] - innerCoordinates[1][1] + heightDifference
      );
      // context.globalCompositeOperation='source-over';
      context.restore();
      setTimeout(() => {
        setLoader(false);
      }, 500);
    };
  };

  const drawPolygonImage = (
    setDimension = false,
    imageSource,
    coordinates = [],
    xCoord,
    yCoord,
    width,
    height
  ) => {
    try {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const image = new Image();
      !setDimension && image.setAttribute("crossOrigin", "anonymous");
      image.src = imageSource;

      image.onload = ({ target: img }) => {
        const coordinatesToPaint = getCoordinatesToPaint(coordinates[0]);
        context.save();
        context.beginPath();

        context.moveTo(coordinates[0][0][0], coordinates[0][0][1]);
        context.lineTo(coordinates[0][1][0], coordinates[0][1][1]);
        context.lineTo(coordinates[0][2][0], coordinates[0][2][1]);
        context.lineTo(coordinates[0][3][0], coordinates[0][3][1]);
        context.lineTo(coordinates[0][4][0], coordinates[0][4][1]);
        context.lineTo(coordinates[0][5][0], coordinates[0][5][1]);

        context.closePath();
        context.clip();
        context.fill();
        context.drawImage(image, coordinatesToPaint.xCoord, coordinatesToPaint.yCoord, coordinatesToPaint.width, coordinatesToPaint.height);
        context.restore();
        setTimeout(() => {
          setLoader(false);
        }, 500);
      };
    } catch (err) {
      console.log("ERRROR:", err);
    }
  };

  const drawImage = (
    setDimension = false,
    imageSource,
    x = 0,
    y = 0,
    width,
    height,
    coordinates,
    rotation
  ) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    !setDimension && image.setAttribute("crossOrigin", "anonymous");
    image.src = imageSource;
    image.onload = async ({ target: img }) => {
      if (setDimension) {
        const width = houseDimensions.x || img.width;
        const height = houseDimensions.y || img.height;
        // const width = isFrontDoor ?  houseDimensions.x : 640;
        // const height = isFrontDoor ?  houseDimensions.y : 480;
        canvas.width = width;
        canvas.height = height;

        context.save();
        context.translate(x, y);
        const rotationThresholdValue = setRotationThresholdValue(rotation);
        // rotation > 0 ? 0.66 : -0.30;
        const finalRotation = rotation / 100 + rotationThresholdValue;
        context.rotate(rotation ? finalRotation : 0);

        context.translate(-x, -y);
        await context.drawImage(
          image,
          x,
          y,
          width || canvas.width,
          height || canvas.height
        );
        context.restore();
      } else {
        if (coordinates && coordinates.length) {
          const catalogImgCoordinates = [...coordinates];
          /* TODO: Enable this condition on real API's data
                    const isRotationRequired = catalogImgCoordinates[0][1] !== catalogImgCoordinates[1][1];
                    */

          context.save();

          context.translate(x, y);
          const rotationThresholdValue = setRotationThresholdValue(
            rotation || propsRotation
          );
          const finalRotation =
            (rotation || propsRotation) / 100 + rotationThresholdValue;
          context.rotate(rotation || propsRotation ? finalRotation : 0);

          context.translate(-x, -y);
          const p = new Perspective(context, image);
          const points = [catalogImgCoordinates[0][0], catalogImgCoordinates[0][1], catalogImgCoordinates[0][2], catalogImgCoordinates[0][3]]
          await p.draw(points);
          context.restore();
          setTimeout(() => {
            setLoader(false);
          }, 500);
          //   }
        }

      }
      isUpload && handleFileUpload(canvas);
    };
  };

  const handleFileUpload = (canvas) => {
    if (canvas.toBlob) {
      const { name, type } = imgData;
      canvas.toBlob(function (blob) {
        let file = new File([blob], name, { type });
        handleIsUpload(file);
      }, type);
    }
  };

  return (
    <div className={classes.canvasContainerWrapper}>
      {loader && (
        <div className={classes.canvasContainerWrapperLoader}>
          <div className={classes.canvasContainerWrapperLoaderOverlay}></div>
          <div
            className={classes.canvasContainerWrapperOverlayLoaderContainer}
          ></div>
          <p className={classes.loaderText}>We are processing your request.  This may take up to 30 seconds.  Thanks for your patience.</p>

        </div>
      )}
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className={isMobileOS && classes.canvasContainerFitContent}>
            {isBrowser && (!isMobile || !isTablet) && (
              <div className={classes.zoomBtnContainer}>
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
              </div>
            )}
            <TransformComponent>
              <div
                className={`${classes.canvasContainer} 
                            ${isResponsiveView && classes.responsiveCanvas}
                            ${isMobileLandscapeView &&
                  classes.mobileLandscapeHeight
                  }
                            `}
                id="canvas-id-container"
                ref={containerRef}
              >
                {imageSrc && <canvas ref={canvasRef} id="canvas-container" />}
              </div>
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default CanvasComponent;