import { connect } from 'react-redux';
import EditComponent from './Edit.component';
import {
    setSelectedCatalogDoor, setSelectedCatalogDoorDimensions, setIsTransformationHappened,
    setUpdateCatalogData
} from '../../redux/edit/actions';
import {setBackStatus, setBackBtnVisibility, setLoaderVisibility} from '../../redux/common/actions';

const mapStateToProps = (state) => {
    return {
        backStatus: state.common.backStatus,
        houseDimensions:state.common.houseImageDimensions,
        selectedHouseImage: state.home.selectedHouseImage,
        selectedDoorsDetails: state.home.selectedDoorsDetails,
        selectedCatalogImage: state.edit.selectedCatalogImage,
        selectedCatalogImageDimensions: state.edit.selectedCatalogImageDimensions,
        selectedCatalogImageData: state.edit.selectedCatalogImageData,
        isTransformationHappened: state.edit.isTransformationHappened,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBackStatus: (payload) => dispatch(setBackStatus(payload)),
        setBackBtnVisibility: (payload) => dispatch(setBackBtnVisibility(payload)),
        setSelectedCatalogDoor: (payload) => dispatch(setSelectedCatalogDoor(payload)),
        setSelectedCatalogDoorDimensions: (payload) => dispatch(setSelectedCatalogDoorDimensions(payload)),
        setIsTransformationHappened: (payload) => dispatch(setIsTransformationHappened(payload)),
        setUpdateCatalogData: (payload) => dispatch(setUpdateCatalogData(payload)),
        setLoaderVisibility: (payload) => dispatch(setLoaderVisibility(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditComponent);