import { combineReducers } from "redux";
import { homeReducer } from "./home/reducer";
import { editReducer } from "./edit/reducer";
import { commonReducer } from "./common/reducer";

const rootReducer = combineReducers({
  home: homeReducer,
  edit: editReducer,
  common: commonReducer,
});

export default rootReducer;
