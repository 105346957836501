import { COMMON_ACTIONS } from "./actionTypes";
import { isMobileDevice, isLandscapeScreen } from "../../utils";

/** Initial state for home reducer */
const initialState = {
  isResponsiveView: document.body.clientWidth < 1025,
  isMobileOS: isMobileDevice(),
  isLandscape: isLandscapeScreen(),
  isMobileLandscapeView: isLandscapeScreen(),
  isLoaderVisible: false,
  isToastrVisible: false,
  garageToastrMessage: "To view new garage doors on your home, please select from the catalog. You can edit the garage door position and size by selecting the pencil icon",
  frontdoorToastrMessage: "To view new front doors on your home, please select from the catalog. You can edit the door position and size by selecting the pencil icon",
  toastrType: "success",
  toastrMessage:"",
  isBackBtnVisible: false,
  backStatus: false,
  houseImageDimensions:{ x:2048, y:1365 },
  isSampleHouse:true,
  exteriorGeneratedImages: null,
  interiorGeneratedImages: null
};

/**
 * Function handing/responsible for updating home component's state
 * @param {object} state
 * @param {object} action
 */
export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.SET_LOADER_VISIBILITY:
      return {
        ...state,
        isLoaderVisible: action.payload,
      };
    case COMMON_ACTIONS.SET_TOASTR_STATUS:
      const { visible, type, message } = action.payload;
      return {
        ...state,
        isToastrVisible: visible,
        toastrMessage: message,
        toastrType: type,
      };
    case COMMON_ACTIONS.SET_SCREEN_ORIENTATION_CHANGE_DATA:
      const {
        isResponsiveView,
        isMobileOS,
        isLandscape,
        isMobileLandscapeView,
      } = action.payload;
      return {
        ...state,
        isResponsiveView,
        isMobileOS,
        isLandscape,
        isMobileLandscapeView,
      };
    case COMMON_ACTIONS.SET_BACK_STATUS:
      return {
        ...state,
        backStatus: action.payload,
      };
    case COMMON_ACTIONS.SET_BACK_BTN_VISIBILITY:
      return {
        ...state,
        isBackBtnVisible: action.payload,
      };
    case COMMON_ACTIONS.SET_IMAGE_DIMENSIONS:
      return {
        ...state,
        houseImageDimensions: action.payload,
      };
    case COMMON_ACTIONS.SET_IS_SAMPLE_HOUSE:
      return {
        ...state,
        isSampleHouse: action.payload,
      };
    case COMMON_ACTIONS.SET_EXTERIOR_GENERATED_IMAGES:
      return {
        ...state,
        exteriorGeneratedImages: action.payload,
      };
    case COMMON_ACTIONS.SET_INTERIOR_GENERATED_IMAGES:
      return {
        ...state,
        interiorGeneratedImages: action.payload,
      };
    default:
      return state;
  }
};
