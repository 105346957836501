import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container } from "react-bootstrap";
import style from "./Interior.module.css";
import { INTERIOR_COLORS, INTERIOR_STYLES } from "../../constants/data";
import InteriorPreviewDetailsComponent from "./InteriorPreviewDetails.component";
import Select from "react-select";
import LazyImage from 'react-lazy-blur-image';

const InteriorPreviewComponent = ({
  interiorDetails,
  onSelectStyle,
  onGenerate,
  setInteriorDetails,
  loading,
}) => {
  const [previewDetails, setPreviewDetails] = useState({
    image: null,
  });
  const [current, setCurrent] = useState(0);

  const openPreviewDetails = (image) => {
    setPreviewDetails({
      image,
    });
  };


  const handleDownloadClick = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = "downloaded-image.jpg";
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };


  const groupedOptions = [
    {
      label: "Style",
      options: INTERIOR_STYLES,
    },
    {
      label: "Color",
      options: INTERIOR_COLORS,
    },
  ];

  const onClose = () => {
    setPreviewDetails({
      image: null,
    });
  };

  const groupStyles = {
    group: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #ccc", // Add a bottom border to separate groups
    }),

    option: (provided, state) => ({
      ...provided,

      backgroundColor: state.isSelected ? "#110e2e" : "white",
      color: state.isSelected ? "white" : provided.color,

      "&:hover": {
        backgroundColor: state.isSelected ? "#110e2e" : "lightblue",
      },
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "14px",
      textTransform: "Capitalize",
    }),

    control: (provided) => ({
      ...provided,
      borderRadius: "0px",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "0px",
      height: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      height: "100%", // Set the max-height of the dropdown list
    }),
  };

  return (
    <>
      {!previewDetails.image && (
        <Container className="mt-5 justify-content-center ">
          <div
            className="position-absolute align-items-center d-flex previewBackLink "
            style={{ top: "30px", marginLeft: "0px", cursor: "pointer" }}
            onClick={() =>
              !loading &&
              setInteriorDetails({ ...interiorDetails, step: "preview" })
            }
          >
            <svg
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              width={16}
              height={16}
            >
              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            Back
          </div>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row" style={{ overflowY: "auto" }}>
                {loading || !interiorDetails.generated.length
                  ? interiorDetails.recentlyGenerated?.response.map(
                    (picture, index) => {
                      return (
                        <div
                          className={`col-12 col-sm-6 ${index === 2 && index === 3 ? "mb-0" : "mb-4"
                            }`}
                          key={index}
                        >
                          <div
                            className="card previewCard position-relative"
                            style={{ height: "200px" }}
                            onClick={() =>
                              picture.src && openPreviewDetails(picture)
                            }
                          >
                            <div
                              className={`${style.bottomRight}`}
                              onClick={(e) => { e.stopPropagation(); picture.src && handleDownloadClick(picture.src) }}
                              style={{

                                cursor: "pointer", zIndex: 1, bottom: "6px", right: "10px"

                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="40"
                                height="40"
                                viewBox="0 0 90 90"
                              >
                                <defs>
                                  <filter
                                    id="Ellipse_13"
                                    x="0"
                                    y="0"
                                    width="90"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset dy="8" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g
                                  id="Group_544"
                                  data-name="Group 544"
                                  transform="translate(1536.825 -5559)"
                                >
                                  <g
                                    transform="matrix(1, 0, 0, 1, -1536.82, 5559)"
                                    filter="url(#Ellipse_13)"
                                  >
                                    <circle
                                      id="Ellipse_13-2"
                                      data-name="Ellipse 13"
                                      cx="36"
                                      cy="36"
                                      r="36"
                                      transform="translate(9 1)"
                                      fill="#fff"
                                    />
                                  </g>
                                  <g
                                    id="Group_543"
                                    data-name="Group 543"
                                    transform="translate(-1506.064 5581.228)"
                                  >
                                    <path
                                      id="Path_249"
                                      data-name="Path 249"
                                      d="M28.432,24.429a2.321,2.321,0,0,0,3.316,0l7.217-7.365a2.479,2.479,0,0,0,0-3.417,2.36,2.36,0,0,0-3.348,0L32.431,16.9V4.922a2.374,2.374,0,1,0-4.746,0V16.9l-3.153-3.284a2.36,2.36,0,0,0-3.348,0,2.479,2.479,0,0,0,0,3.417Z"
                                      transform="translate(-15.922 -2.5)"
                                      fill="#2e2e2e"
                                    />
                                    <path
                                      id="Path_250"
                                      data-name="Path 250"
                                      d="M32.615,63.4a2.419,2.419,0,0,0-2.389,2.422V69.9H11.477V65.822a2.389,2.389,0,1,0-4.777,0v6.469a2.419,2.419,0,0,0,2.389,2.422H32.615A2.419,2.419,0,0,0,35,72.291V65.822A2.4,2.4,0,0,0,32.615,63.4Z"
                                      transform="translate(-6.7 -44.994)"
                                      fill="#2e2e2e"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            {index === 0 ? (
                              <span
                                style={{
                                  top: "10px",
                                  left: "10px",
                                  background: "#110e2e",
                                  fontSize: "12px",
                                  zIndex: "10",
                                }}
                                className="position-absolute p-2 text-white"
                              >
                                Original
                              </span>
                            ) : (
                              <span
                                style={{
                                  top: "10px",
                                  left: "10px",
                                  fontSize: "12px",
                                  background: "#4c84ff",
                                  zIndex: "10",
                                }}
                                className=" position-absolute p-2 text-white"
                              >
                                {interiorDetails.recentlyGenerated?.style}{" "}
                                {index}
                              </span>
                            )}
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%", position: "relative" }}
                            >
                              {
                                picture.src != null ?
                                <LazyImage
                                      uri={picture.src}
                                      placeholder={URL.createObjectURL(interiorDetails.file)}
                                      render={(src, style) => <img alt="" src={src} style={{
                                        ...style, width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        opacity: src === null ? "0.2" : "1",
                                        cursor: "pointer"
                                      }} />}
                                    />
                                    :
                                    <img
                                      src={picture.src}
                                      className={`card-img-top ${picture.isLoading ? "blurred" : ""
                                        }`}
                                      alt={`preview `}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        opacity: picture.src === null ? "0.2" : "1",
                                        cursor: "pointer",
                                      }}
                                    />
                                }


                              {picture.src === null && (

                                <>
                                  <svg
                                    className={`position-absolute loader`}
                                    style={{ width: "50px", height: "50px" }}
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1069 593"
                                  >
                                    <defs>
                                      <clipPath id="clip-Modfai">
                                        <rect width="1069" height="593" />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Modfai"
                                      clip-path="url(#clip-Modfai)"
                                    >
                                      <path
                                        id="Path_249"
                                        data-name="Path 249"
                                        d="M42,297.593,289.268,58.723,530.774,297.451V527.068H42ZM289.607,0,0,279.769v289.3H572.776V279.912Z"
                                        transform="translate(108 11)"
                                        fill="#162261"
                                      />
                                      <path
                                        id="Path_250"
                                        data-name="Path 250"
                                        d="M222.322,297.593,469.591,58.723,711.1,297.451V527.068H222.322ZM469.928,0,180.321,279.769v289.3H753.1V279.912Z"
                                        transform="translate(108 11)"
                                        fill="#0d39be"
                                      />
                                      <path
                                        id="Path_251"
                                        data-name="Path 251"
                                        d="M413.83,297.593,661.1,58.723,902.6,297.451V527.068H413.83ZM661.436,0,371.829,279.769v289.3H944.6V279.912Z"
                                        transform="translate(108 11)"
                                        fill="#459377"
                                      />
                                    </g>
                                  </svg>
                                  <p className={`position-absolute top-1 ${index == 0 ? "d-none" : "block"}`} style={{ color: "black", fontSize: "12px", bottom: "42px" }}>Our AI is working its magic! Outputs are on their way</p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                  : interiorDetails.generated[current]?.response.map(
                    (picture, index) => {
                      return (
                        <div
                          className={`col-12 col-sm-6 ${index === 2 && index === 3 ? "mb-0" : "mb-4"
                            }`}
                          key={index}
                        >
                          <div
                            className="card previewCard position-relative"
                            style={{ height: "200px" }}
                            onClick={() =>
                              picture.src && openPreviewDetails(picture)
                            }
                          >
                            <div
                              className={`${style.bottomRight}`}
                              onClick={(e) => { e.stopPropagation(); picture.src && handleDownloadClick(picture.src) }}
                              style={{
                                cursor: "pointer", zIndex: 1, bottom: "6px", right: "16px"
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="40"
                                height="40"
                                viewBox="0 0 90 90"
                              >
                                <defs>
                                  <filter
                                    id="Ellipse_13"
                                    x="0"
                                    y="0"
                                    width="90"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset dy="8" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g
                                  id="Group_544"
                                  data-name="Group 544"
                                  transform="translate(1536.825 -5559)"
                                >
                                  <g
                                    transform="matrix(1, 0, 0, 1, -1536.82, 5559)"
                                    filter="url(#Ellipse_13)"
                                  >
                                    <circle
                                      id="Ellipse_13-2"
                                      data-name="Ellipse 13"
                                      cx="36"
                                      cy="36"
                                      r="36"
                                      transform="translate(9 1)"
                                      fill="#fff"
                                    />
                                  </g>
                                  <g
                                    id="Group_543"
                                    data-name="Group 543"
                                    transform="translate(-1506.064 5581.228)"
                                  >
                                    <path
                                      id="Path_249"
                                      data-name="Path 249"
                                      d="M28.432,24.429a2.321,2.321,0,0,0,3.316,0l7.217-7.365a2.479,2.479,0,0,0,0-3.417,2.36,2.36,0,0,0-3.348,0L32.431,16.9V4.922a2.374,2.374,0,1,0-4.746,0V16.9l-3.153-3.284a2.36,2.36,0,0,0-3.348,0,2.479,2.479,0,0,0,0,3.417Z"
                                      transform="translate(-15.922 -2.5)"
                                      fill="#2e2e2e"
                                    />
                                    <path
                                      id="Path_250"
                                      data-name="Path 250"
                                      d="M32.615,63.4a2.419,2.419,0,0,0-2.389,2.422V69.9H11.477V65.822a2.389,2.389,0,1,0-4.777,0v6.469a2.419,2.419,0,0,0,2.389,2.422H32.615A2.419,2.419,0,0,0,35,72.291V65.822A2.4,2.4,0,0,0,32.615,63.4Z"
                                      transform="translate(-6.7 -44.994)"
                                      fill="#2e2e2e"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            {index === 0 ? (
                              <span
                                style={{
                                  top: "10px",
                                  left: "10px",
                                  background: "#110e2e",
                                  fontSize: "12px",
                                  zIndex: 20,
                                  border: "1px solid white",
                                }}
                                className="position-absolute p-2 text-white"
                              >
                                Original
                              </span>
                            ) : (
                              <span
                                style={{
                                  top: "10px",
                                  left: "10px",
                                  fontSize: "12px",
                                  background: "#4c84ff",
                                  zIndex: 20,
                                  border: "1px solid white",
                                }}
                                className=" position-absolute p-2 text-white"
                              >
                                {interiorDetails.generated[current]?.style}{" "}
                                {index}
                              </span>
                            )}
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%", position: "relative" }}
                            >

                              <LazyImage
                                uri={picture.src}
                                placeholder={URL.createObjectURL(interiorDetails.file)}
                                render={(src, style) => <img alt="" src={src} style={{
                                  ...style, width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  opacity: src === null ? "0.2" : "1",
                                  cursor: "pointer"
                                }} />}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                <div
                  style={{ bottom: "-10px", right: "15px", gap: "5px" }}
                  className="position-absolute d-flex end-0 pt-2"
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      !loading && current && setCurrent(current - 1);
                    }}
                  >
                    <svg
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={10}
                    >
                      <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </div>{" "}
                  {current + 1}/
                  {loading
                    ? interiorDetails.generated.length + 1
                    : interiorDetails.generated.length}{" "}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      !loading &&
                      current < interiorDetails.generated.length - 1 &&
                      setCurrent(current + 1)
                    }
                  >
                    <svg
                      width={10}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 mb-4 mt-4 mt-sm-0">
              <div className={`d-flex ${style.interiorStyle}`}>
                <div>
                  <h5 className="mb-4">Choose Your Interior Style</h5>

                  <Select
                    placeholder="Select Style"
                    className={`${style.dropdownList}`}
                    options={groupedOptions}
                    styles={groupStyles}
                    onChange={(selectedValue) => {
                      onSelectStyle(selectedValue.value);
                    }}
                    value={
                      interiorDetails.style
                        ? {
                          label: interiorDetails.style,
                          value: interiorDetails.style,
                        }
                        : null
                    }
                  />
                </div>
                <div className="pt-5 pt-sm-3 pt-lg-0">
                  <div>
                    <div className={style.DropdownButtonWrapper}>
                      <Button
                        onClick={onGenerate}
                        disabled={
                          !interiorDetails.style ||
                          !interiorDetails.file ||
                          loading
                        }
                      >
                        {loading ? "Generating..." : "Generate"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      {previewDetails.image && (
        <InteriorPreviewDetailsComponent
          onClose={onClose}
          selectedImage={previewDetails.image}

          handleDownloadClick={handleDownloadClick}
        />
      )}
    </>
  );
};

export default InteriorPreviewComponent;
