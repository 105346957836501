import { connect } from 'react-redux';
import React from 'react';
import classes from './Loader.module.css';

const mapStateToProps = (state) => {
    return {
        isLoaderVisible: state.common.isLoaderVisible
    }
}

const Loader = React.memo(({ isLoaderVisible }) => {
    return (
        isLoaderVisible && (
            <div className={classes.loaderContainerWrapper}>
                <div className={classes.loaderOverlay}></div>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className={classes.loaderContainer}></div>
                    <p className={classes.loaderText}>We are processing your request.  This may take up to 30 seconds.  Thanks for your patience.</p>
                </div>
            </div>
        )
    );
});

export default connect(mapStateToProps, null)(Loader);