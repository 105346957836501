import axios from "axios";

// Development base URL
// const BASE_URL = "https://devappmodifai.link"

// Production base URL
const BASE_URL = "https://prodappmodifai.link" 

const IMAGE_UPLOAD_URL = "https://mlservermodifai.link";

export const EXTERIOR_UPLOAD = "https://exteriormodifai.com/api/upload";

export const getCatalogList = async (url) => {
  const response = await axios.get(url || `${BASE_URL}/GetCatalog/all_home_objects`);
  return response;
};

export const getData = async (url) => {
  const response = await axios.get(`${BASE_URL}${url}`);
  return response;
};

export const postData = async (url, data) => {
  const response = await axios.post(`${BASE_URL}${url}`, data);
  return response;
};

export const uploadImage = async (url, data) => {
  const response = await axios.post(`${IMAGE_UPLOAD_URL}${url}`, data);
  return response;
};

export const get = async (url) => {
  const response = await axios.get(`${url}`);
  return response;
};

export const post = async (url, data) => {
  const response = await axios.post(`${url}`, data);
  return response;
};
