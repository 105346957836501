import React, { useState, useRef } from "react";
import classes from "../../garage/Garage.module.css";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FunnelFill, ShareFill, PencilFill } from "react-bootstrap-icons";
import * as _ from "lodash";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom";
import CanvasComponent from "../canvas/Canvas.component";
import { FileUpload } from "../input/Input.component";
import {
  SwiperComponent,
  SwiperComp,
} from "../swiper/Swiper.component";
import Contact from "../contact/Contact.component";
import { Title } from "../title/Title.component";
import Loader from "../loader/Loader.component";
import {
  DeviceOSModal,
  QuoteEmailModal,
  FilterModal,
  QuoteEmailSuccessModal,
  //   ConfirmationModal,
} from "../modal/Modal.component";
import ToastrComponent from "../toastr/Toastr.component";
import AppStoreImg from "../../../assets/images/appstore.png";
import EmailComponent from "../email/Email.component";
import { useSelector } from "react-redux";
import EditIcon from '../../../assets/images/Edit.svg'
import ShareIcon from '../../../assets/images/Share.svg'
import FilterIcon from '../../../assets/images/Filter.svg'

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))


const LandscapeView = ({
  houseData = [],
  catalogData = [],
  imgSrc,
  objectSrc,
  selectedCatalogName,
  objectCoordinates,
  isUpload,
  imgData,
  garageDoorDraw,
  selectedTypeData,
  isMobileLandscapeView,
  isLandscape,
  handleIsUpload,
  handleSetFileImg,
  handleHouseSlideClick,
  handleSlideClick,
  isDeviceOSModalVisible,
  handleDeviceOSModalClick,
  isMobileOS,
  isIOSDevice,
  isResponsiveView,
  selectedCatalogID,
  shapeType,
  handleFilterApply,
  setToastrStatus,
  getCatalogData,
  sendEmail,
  setLoaderVisibility,
  rotation,
  selectedCatalogImage,
  selectedCatalogImageData,
  isFrontDoor,
  setSelectedDoordDetails,
  selectedDoorsDetails,
  filterVariables,
  isInitialToaster,
  setIsInitialToaster,
  setting

}) => {
  const garageToastrMessage = useSelector(
    (state) => state.common.garageToastrMessage
  );
  const frontdoorToastrMessage = useSelector(
    (state) => state.common.frontdoorToastrMessage
  );

  const domRef = useRef(null);
  const { color, style, price } = filterVariables;
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isFilterModalActive, setIsFilterModalActive] = useState(
    color || style || price
  );
  const [requestQuoteData, setRequestQuoteData] = useState({});
  const [showRequestQuote] = useState(false);

  const history = useHistory();
  const handleEmailClick = () => {
    if (objectSrc || selectedCatalogImage) {
      setIsEmailModalVisible(true);
    } else {
      setToastrStatus({
        visible: true,
        type: "error",
        message:
          "Please select a garage door before proceeding with quote request",
      });
    }
  };

  const handleModalClick = () => {
    setIsEmailModalVisible(false);
  };

  const handleProposalSubmitClick = (data = {}) => {
    setLoaderVisibility(true);
    
    setRequestQuoteData(data);

    const doc = new jsPDF('portrait', 'pc', 'a4')
    toDataURL(objectSrc)
      .then(async (imgData) => {
        doc.setFontSize(20);
        doc.text(18, 2, `Product List`);
        doc.text(1, 5, `Customer Details`);
        doc.setFontSize(15);
        doc.text(2, 7, `First name: ${data.firstName}`);
        doc.text(2, 9, `Last name: ${data.lastName}`);
        doc.text(2, 11, `Email: ${data.email}`);
        doc.text(2, 13, `Phone: ${data.phoneNumber}`);
        doc.text(2, 15, `Zip: ${data.zip}`);
        doc.addImage(imgSrc, `JPEG`, 9, 18, 30, 20);
        doc.addImage(imgData, `JPEG`, 2, 42, 20, 12);
        doc.text(25, 47, `${selectedCatalogName}`);

        const pdfBlob = doc.output("blob");
        const file = new File([pdfBlob], "Request_Quote.pdf", {
          type: "application/pdf",
        });
        await sendEmail({
          email: data.email,
          proposal_url: file,
          phoneno: data.phoneNumber,
          first_name: data.firstName,
          last_name: data.lastName,
          zip: data.zip
        });
      }).then((response) => {
        setIsEmailModalVisible(false);
        setIsEmailSentSuccess(true);
      }).catch(error => {
        console.error('oops, something went wrong!', error);
        setLoaderVisibility(false);
      })
  };

  const handleFilterClick = () => {
    setIsFilterModalVisible(true);
  };

  const handleFilterModalClick = () => {
    setIsFilterModalVisible(false);
  };

  const handleApply = (params) => {
    handleFilterApply(params);
    if (params) {
      setIsFilterModalActive(true);
    } else {
      setIsFilterModalActive(false);
    }
  };
  const handleEditClick = () => {
    history.push(isFrontDoor ? "edit-door" : "edit-garage");
  };

  const handleShareClick = () => {
    if (objectSrc || selectedCatalogImage) {
      const canvasContainer = document.getElementById("canvas-container");
      if (canvasContainer.toBlob) {
        setLoaderVisibility(true);
        canvasContainer.toBlob(
          function (blob) {
            const file = new File([blob], "ModifAi_Garage.png", {
              type: "image/png",
            });

            if (navigator.canShare && navigator.canShare({ files: [file] })) {
              setLoaderVisibility(false);
              navigator
                .share({
                  files: [file],
                  title: "ModifAi",
                  text: "Thanks you for connecting with ModifAi",
                })
                .then(() => console.log("Share was successful."))
                .catch((error) => {
                  setToastrStatus({
                    visible: true,
                    type: "error",
                    message: "Error while sharing. Please try again",
                  });
                  setLoaderVisibility(false);
                });
            } else {
              setLoaderVisibility(false);
              console.log(`Your system doesn't support sharing files.`);
            }
          },
          (err) => {
            setLoaderVisibility(false);
          }
        );
      }
    } else {
      setToastrStatus({
        visible: true,
        type: "error",
        message: "Please select a garage door before proceeding with share",
      });
    }
  };

  const handleClose = () => {
    setIsEmailSentSuccess(false)
  }

  const isContactForDesktop = !isMobileOS || isMobileLandscapeView;
  return (
    <div
      className={`${classes.mobileLandscapeContainerWrapper} ${isMobileLandscapeView && classes.mobileLandscape
        }`}
    >
      <div className={classes.requestQuoteContainer}>
        <div id="request-quote">
          <EmailComponent
            houseImg={imgSrc}
            catalogImg={objectSrc}
            data={requestQuoteData}
            selectedCatalogName={selectedCatalogName}
          />
        </div>
      </div>
      <Loader />
      <ToastrComponent />
      {/* <ShareComponent show={true} /> */}
      <FilterModal
        show={isFilterModalVisible}
        handleClose={handleFilterModalClick}
        handleApply={handleApply}
        setIsFilterModalActive={setIsFilterModalActive}
        filterVariables={filterVariables}
      />
      {/* {isMobileOS && (
        <DeviceOSModal
          show={isDeviceOSModalVisible}
          handleClose={handleDeviceOSModalClick}
          isFrontDoor={isFrontDoor}
          setting={setting}
        />
      )} */}
      
      {/*       
        <ConfirmationModal
          show={isInitialToaster}
          message={isFrontDoor ? frontdoorToastrMessage : garageToastrMessage}
          confirmBtn={"Get Started"}
          handleClose={setIsInitialToaster}
        />
      )} */}

      <QuoteEmailModal
        show={isEmailModalVisible}
        handleClose={handleModalClick}
        handleProposalSubmitClick={handleProposalSubmitClick}
      />
      <QuoteEmailSuccessModal show={isEmailSentSuccess} handleClose={handleClose}/>

      <div className={classes.mobileLandscapeContainer}>
        <div className={classes.mobileHomeImgContainer}>
          <FileUpload
            setHouseImg={handleSetFileImg}
            isLandscapeView={true}
            isMobileOS={isMobileOS}
            isResponsiveView={isResponsiveView}
            isFrontDoor={isFrontDoor}
          />
          <Title title="Choose from sample homes" isMobileOS={isMobileOS} />
          <SwiperComp
            data={houseData}
            id="house-container"
            handleSlideClick={handleHouseSlideClick}
            isHouseImg={true}
            setSelectedDoordDetails={setSelectedDoordDetails}
          />
        </div>
        <div className={classes.mobileCanvasContainer}>
          <div
            className={`${classes.actionBtnContainer} ${isMobileLandscapeView && classes.actionBtnForLandscape
              }`}
          >
            <Title title="Preview Your Image" />
            <div className={classes.actionIcon}>
              <OverlayTrigger
                key="top-edit"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
              >
                <img src={EditIcon} alt="Edit" onClick={handleEditClick} />
                {/* <PencilFill color="#1C224B" size={22} onClick={handleEditClick} /> */}
              </OverlayTrigger>
              <OverlayTrigger
                key="top-share"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Share</Tooltip>}
              >
                <img src={ShareIcon} alt="Share" onClick={handleShareClick} />
                {/* <ShareFill color="#1C224B" size={22} onClick={handleShareClick} /> */}
              </OverlayTrigger>
              <OverlayTrigger
                key="top-filter"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Filter</Tooltip>}
              >
                <div style={{ display: 'inline-block', position: 'relative' }}>
                  <img src={FilterIcon} alt="Filter" onClick={handleFilterClick} />
                  <span className={isFilterModalActive ? classes.filterActive : ''}></span>
                </div>
                {/* <FunnelFill color="#1C224B" size={22} onClick={handleFilterClick} /> */}
              </OverlayTrigger>
            </div>
          </div>
          <CanvasComponent
            imageSrc={imgSrc}
            objectImgSrc={objectSrc}
            coordinates={objectCoordinates}
            isUpload={isUpload}
            imgData={imgData}
            garageDoorDraw={garageDoorDraw}
            handleIsUpload={handleIsUpload}
            shapeType={shapeType}
            isMobileOS={isMobileOS}
            isMobileLandscapeView={isMobileLandscapeView}
            isResponsiveView={isResponsiveView}
            domRef={domRef}
            rotation={rotation}
            selectedDoorsDetails={selectedDoorsDetails}
            selectedCatalogImage={selectedCatalogImage}
            isFrontDoor={isFrontDoor}
          />
          {isContactForDesktop && (
            <Contact
              isMobileLandscape={isMobileLandscapeView}
              isMobileOS={isMobileOS}
              isIOSDevice={isIOSDevice}
              handleEmailClick={handleEmailClick}
              isFrontDoor={isFrontDoor}
              setting={setting}
            />
          )}
        </div>
        <div
          className={`${classes.mobileCatalogImgContainer} ${isMobileLandscapeView && classes.mobileCatalog
            }`}
        >
          <Title title='Select Your Door'></Title>
          <SwiperComp
            data={catalogData}
            id="door-container"
            handleSlideClick={handleSlideClick}
            isMobileOS={isMobileOS}
            selectedCatalogID={selectedCatalogID}
            selectedCatalogImageData={selectedCatalogImageData}
            isFrontDoor={isFrontDoor}
            setSelectedDoordDetails={setSelectedDoordDetails}
          />
        </div>
      </div>
      {isMobileOS && !isMobileLandscapeView && (
        <div className={classes.mobileLandscapeContactContainer}>
          <Contact
            isMobileLandscape={isMobileLandscapeView}
            isMobileOS={isMobileOS}
            isIOSDevice={isIOSDevice}
            handleEmailClick={handleEmailClick}
            isFrontDoor={isFrontDoor}
            setting={setting}
          />
        </div>
      )}
    </div>
  );
};

const PortraitView = ({
  houseData = [],
  catalogData = [],
  imgSrc,
  objectSrc,
  selectedCatalogName,
  objectCoordinates,
  isUpload,
  imgData,
  garageDoorDraw,
  selectedTypeData,
  isMobileLandscapeView,
  handleIsUpload,
  handleSetFileImg,
  handleHouseSlideClick,
  handleSlideClick,
  isDeviceOSModalVisible,
  handleDeviceOSModalClick,
  isMobileOS,
  isIOSDevice,
  isResponsiveView,
  selectedCatalogID,
  shapeType,
  handleFilterApply,
  setToastrStatus,
  getCatalogData,
  sendEmail,
  setLoaderVisibility,
  selectedCatalogImage,
  rotation,
  selectedCatalogImageData,
  isFrontDoor,
  setSelectedDoordDetails,
  selectedDoorsDetails,
  filterVariables,
  isInitialToaster,
  setIsInitialToaster,
  setting

}) => {
  const garageToastrMessage = useSelector(
    (state) => state.common.garageToastrMessage
  );
  const frontdoorToastrMessage = useSelector(
    (state) => state.common.frontdoorToastrMessage
  );

  const { color, style, price } = filterVariables;
  const [isFilterModalActive, setIsFilterModalActive] = useState(
    color || style || price
  );
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [requestQuoteData, setRequestQuoteData] = useState({});
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);

  const history = useHistory();
  const handleEmailClick = () => {
    if (objectSrc || selectedCatalogImage) {
      setIsEmailModalVisible(true);
    } else {
      setToastrStatus({
        visible: true,
        type: "error",
        message:
          "Please select a garage door before proceeding with quote request",
      });
    }
  };

  const handleModalClick = () => {
    setIsEmailModalVisible(false);
  };

  const handleProposalSubmitClick = (data = {}) => {
    setLoaderVisibility(true);
    setIsEmailModalVisible(false);
    setRequestQuoteData(data);

    const doc = new jsPDF('portrait', 'pc', 'a4')
    toDataURL(objectSrc)
      .then(async (imgData) => {
        doc.setFontSize(20);
        doc.text(18, 2, `Product List`);
        doc.text(1, 5, `Customer Details`);
        doc.setFontSize(15);
        doc.text(2, 7, `First name: ${data.firstName}`);
        doc.text(2, 9, `Last name: ${data.lastName}`);
        doc.text(2, 11, `Email: ${data.email}`);
        doc.text(2, 13, `Phone: ${data.phoneNumber}`);
        doc.text(2, 15, `Zip: ${data.zip}`);
        doc.addImage(imgSrc, `JPEG`, 9, 18, 30, 20);
        doc.addImage(imgData, `JPEG`, 2, 42, 20, 12);
        doc.text(25, 47, `${selectedCatalogName}`);

        const pdfBlob = doc.output("blob");
        const file = new File([pdfBlob], "Request_Quote.pdf", {
          type: "application/pdf",
        });
        await sendEmail({
          email: data.email,
          proposal_url: file,
          phoneno: data.phoneNumber,
          first_name: data.firstName,
          last_name: data.lastName,
          zip: data.zip
        });
      }).then((response) => {
        setIsEmailModalVisible(false);
        setIsEmailSentSuccess(true);
      }).catch(error => {
        console.error('oops, something went wrong!', error);
        setLoaderVisibility(false);
      })
  };

  const handleFilterClick = () => {
    setIsFilterModalVisible(true);
  };

  const handleFilterModalClick = () => {
    setIsFilterModalVisible(false);
  };

  const handleApply = (params) => {
    if (params) {
      handleFilterApply(params);
    } else {
      getCatalogData(selectedCatalogID);
    }
  };

  const handleShareClick = () => {
    if (objectSrc || selectedCatalogImage) {
      const canvasContainer = document.getElementById("canvas-container");
      if (canvasContainer.toBlob) {
        setLoaderVisibility(true);
        canvasContainer.toBlob(
          function (blob) {
            const file = new File([blob], "ModifAi_Garage.png", {
              type: "image/png",
            });

            if (navigator.canShare && navigator.canShare({ files: [file] })) {
              setLoaderVisibility(false);
              navigator
                .share({
                  files: [file],
                  title: "ModifAi",
                  text: "Thanks you for connecting with ModifAi",
                })
                .then(() => console.log("Share was successful."))
                .catch((error) => {
                  setToastrStatus({
                    visible: true,
                    type: "error",
                    message: "Error while sharing. Please try again",
                  });
                  setLoaderVisibility(false);
                });
            } else {
              setLoaderVisibility(false);
              console.log(`Your system doesn't support sharing files.`);
            }
          },
          (err) => {
            setLoaderVisibility(false);
          }
        );
      }
    } else {
      setToastrStatus({
        visible: true,
        type: "error",
        message: "Please select a garage door before proceeding with share",
      });
    }
  };

  const handleEditClick = () => {
    history.push(isFrontDoor ? "edit-door" : "edit-garage");
  };

  const handleClose = () => {
    setIsEmailSentSuccess(false)
  }

  return (
    <>
      <div className={classes.requestQuoteContainer}>
        <div id="request-quote">
          <EmailComponent
            houseImg={imgSrc}
            catalogImg={objectSrc}
            data={requestQuoteData}
            selectedCatalogName={selectedCatalogName}
          />
        </div>
      </div>
      <Loader />
      <ToastrComponent />

      {/* <ConfirmationModal
          show={isInitialToaster}
          message={isFrontDoor ? frontdoorToastrMessage : garageToastrMessage}
          confirmBtn={"Get Started"}
          handleClose={setIsInitialToaster}
        /> */}

      <FilterModal
        show={isFilterModalVisible}
        handleClose={handleFilterModalClick}
        handleApply={handleFilterApply}
        setIsFilterModalActive={setIsFilterModalActive}
        filterVariables={filterVariables}
      />
      {/* {isMobileOS && (
        <DeviceOSModal
          show={isDeviceOSModalVisible}
          handleClose={handleDeviceOSModalClick}
          isFrontDoor={isFrontDoor}
          setting={setting}
        />
      )} */}
      <QuoteEmailModal
        show={isEmailModalVisible}
        handleClose={handleModalClick}
        handleProposalSubmitClick={handleProposalSubmitClick}
      />
      <QuoteEmailSuccessModal show={isEmailSentSuccess} handleClose={handleClose}/>
      <div
        className={`${classes.homeContainerWrapper} ${isMobileOS && classes.homeContainerMobileWrapper
          }`}
      >
        <div className={classes.homeContainer}>
          <Row className="justify-content-md-center no-margin">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <FileUpload
                    setHouseImg={handleSetFileImg}
                    isMobileOS={isMobileOS}
                    isResponsiveView={isResponsiveView}
                    isLandscapeView={isMobileLandscapeView}
                    isFrontDoor={isFrontDoor}
                  />
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Title
                    title="Choose from sample homes"
                    isMobileOS={isMobileOS}
                  />
                  {houseData.length &&
                    (isResponsiveView ? (
                      <SwiperComponent
                        data={houseData}
                        handleSlideClick={handleHouseSlideClick}
                        isHomeSwipper={true}
                        isResponsiveView={isResponsiveView}
                        slidesPerView={1}
                        isFrontDoor={isFrontDoor}
                        selectedCatalogImageData={selectedCatalogImageData}
                      />
                    ) : (
                      <SwiperComp
                        data={houseData}
                        id="house-container"
                        handleSlideClick={handleHouseSlideClick}
                        isHouseImg={true}
                        selectedCatalogImageData={selectedCatalogImageData}
                        isFrontDoor={isFrontDoor}
                      />
                    ))}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <CanvasComponent
                imageSrc={imgSrc}
                objectImgSrc={objectSrc}
                coordinates={objectCoordinates}
                isUpload={isUpload}
                imgData={imgData}
                garageDoorDraw={garageDoorDraw}
                handleIsUpload={handleIsUpload}
                shapeType={shapeType}
                isMobileLandscapeView={isMobileLandscapeView}
                isMobileOS={isMobileOS}
                isResponsiveView={isResponsiveView}
                rotation={rotation}
                selectedDoorsDetails={selectedDoorsDetails}
                selectedCatalogImage={selectedCatalogImage}
                isFrontDoor={isFrontDoor}
              />
              <div
                className={`${classes.actionBtnContainer} ${classes.actionBtnForMobile}`}
              >
                <OverlayTrigger
                  key="mobile-top-edit"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
                >
                  <PencilFill
                    color="#1C224B"
                    size={22}
                    onClick={handleEditClick}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="mobile-top-share"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Share</Tooltip>}
                >
                  <ShareFill
                    color="#1C224B"
                    size={22}
                    onClick={handleShareClick}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="mobile-top-filter"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Filter</Tooltip>}
                >
                  <>
                    <FunnelFill
                      color="#1C224B"
                      size={22}
                      onClick={handleFilterClick}
                    />
                    <span
                      className={isFilterModalActive && classes.filterActive}
                    ></span>
                  </>
                </OverlayTrigger>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              {selectedTypeData.length > 0 &&
                (isResponsiveView ? (
                  <SwiperComponent
                    data={selectedTypeData}
                    selected={objectSrc}
                    slidesPerView={4}
                    isResponsiveView={isResponsiveView}
                    handleSlideClick={handleSlideClick}
                    isFrontDoor={isFrontDoor}
                    setSelectedDoordDetails={setSelectedDoordDetails}
                  />
                ) : (
                  <SwiperComp
                    data={selectedTypeData}
                    id="door-container"
                    handleSlideClick={handleSlideClick}
                    isFrontDoor={isFrontDoor}
                    setSelectedDoordDetails={setSelectedDoordDetails}
                  />
                ))}
            </Col>
          </Row>
          <Contact
            isMobileOS={isMobileOS}
            isIOSDevice={isIOSDevice}
            handleEmailClick={handleEmailClick}
            isFrontDoor={isFrontDoor}
            setting={setting}
          />
        </div>
      </div>
    </>
  );
};

export const TabletView = ({
  houseData = [],
  catalogData = [],
  imgSrc,
  objectSrc,
  selectedCatalogName,
  objectCoordinates,
  isUpload,
  imgData,
  garageDoorDraw,
  handleIsUpload,
  handleSetFileImg,
  handleHouseSlideClick,
  handleSlideClick,
  isDeviceOSModalVisible,
  handleDeviceOSModalClick,
  isMobileLandscapeView,
  isLandscape,
  isMobileOS,
  isIOSDevice,
  isResponsiveView,
  selectedCatalogID,
  shapeType,
  handleFilterApply,
  setToastrStatus,
  getCatalogData,
  sendEmail,
  setLoaderVisibility,
  selectedTypeData,
  selectedCatalogImage,
  rotation,
  selectedCatalogImageData,
  isFrontDoor,
  setSelectedDoordDetails,
  selectedDoorsDetails,
  filterVariables,
  setIsInitialToaster,
  isInitialToaster,
  setting

}) => {
  return isMobileLandscapeView || isLandscape ? (
    <LandscapeView
      houseData={houseData}
      catalogData={catalogData}
      imgSrc={imgSrc}
      objectSrc={objectSrc}
      selectedCatalogName={selectedCatalogName}
      objectCoordinates={objectCoordinates}
      isUpload={isUpload}
      imgData={imgData}
      garageDoorDraw={garageDoorDraw}
      isMobileLandscapeView={isMobileLandscapeView}
      isLandscape={isLandscape}
      isMobileOS={isMobileOS}
      isResponsiveView={isResponsiveView}
      isIOSDevice={isIOSDevice}
      handleIsUpload={handleIsUpload}
      handleSetFileImg={handleSetFileImg}
      handleHouseSlideClick={handleHouseSlideClick}
      handleSlideClick={handleSlideClick}
      isDeviceOSModalVisible={isDeviceOSModalVisible}
      shapeType={shapeType}
      handleDeviceOSModalClick={handleDeviceOSModalClick}
      setToastrStatus={setToastrStatus}
      getCatalogData={getCatalogData}
      sendEmail={sendEmail}
      setLoaderVisibility={setLoaderVisibility}
      handleFilterApply={handleFilterApply}
      selectedCatalogImage={selectedCatalogImage}
      rotation={rotation}
      isFrontDoor={isFrontDoor}
      setSelectedDoordDetails={setSelectedDoordDetails}
      selectedDoorsDetails={selectedDoorsDetails}
      filterVariables={filterVariables}
      setIsInitialToaster={setIsInitialToaster}
      isInitialToaster={isInitialToaster}
      setting={setting}

    />
  ) : (
    <PortraitView
      houseData={houseData}
      catalogData={selectedTypeData}
      selectedTypeData={catalogData}
      imgSrc={imgSrc}
      objectSrc={objectSrc}
      selectedCatalogName={selectedCatalogName}
      objectCoordinates={objectCoordinates}
      isUpload={isUpload}
      imgData={imgData}
      garageDoorDraw={garageDoorDraw}
      isMobileLandscapeView={isMobileLandscapeView}
      isMobileOS={isMobileOS}
      isResponsiveView={isResponsiveView}
      isIOSDevice={isIOSDevice}
      handleIsUpload={handleIsUpload}
      handleSetFileImg={handleSetFileImg}
      handleHouseSlideClick={handleHouseSlideClick}
      handleSlideClick={handleSlideClick}
      isDeviceOSModalVisible={isDeviceOSModalVisible}
      shapeType={shapeType}
      handleDeviceOSModalClick={handleDeviceOSModalClick}
      setToastrStatus={setToastrStatus}
      getCatalogData={getCatalogData}
      sendEmail={sendEmail}
      setLoaderVisibility={setLoaderVisibility}
      handleFilterApply={handleFilterApply}
      isLandscape={isLandscape}
      selectedCatalogImage={selectedCatalogImage}
      rotation={rotation}
      isFrontDoor={isFrontDoor}
      setSelectedDoordDetails={setSelectedDoordDetails}
      selectedDoorsDetails={selectedDoorsDetails}
      filterVariables={filterVariables}
      setIsInitialToaster={setIsInitialToaster}
      isInitialToaster={isInitialToaster}
      setting={setting}

    />
  );
};

export const MobileViewComponent = ({
  houseData = [],
  catalogData = [],
  imgSrc,
  objectSrc,
  selectedCatalogName,
  objectCoordinates,
  isUpload,
  imgData,
  garageDoorDraw,
  handleIsUpload,
  handleSetFileImg,
  handleHouseSlideClick,
  handleSlideClick,
  isDeviceOSModalVisible,
  handleDeviceOSModalClick,
  isMobileLandscapeView,
  isMobileOS,
  isIOSDevice,
  isResponsiveView,
  selectedCatalogID,
  shapeType,
  handleFilterApply,
  setToastrStatus,
  getCatalogData,
  sendEmail,
  setLoaderVisibility,
  selectedTypeData,
  selectedCatalogImage,
  rotation,
  selectedCatalogImageData,
  isFrontDoor,
  setSelectedDoordDetails,
  selectedDoorsDetails,
  filterVariables,
  setIsInitialToaster,
  isInitialToaster,
  setting

}) => {
  return isMobileLandscapeView ? (
    <LandscapeView
      houseData={houseData}
      catalogData={catalogData}
      imgSrc={imgSrc}
      objectSrc={objectSrc}
      selectedCatalogName={selectedCatalogName}
      objectCoordinates={objectCoordinates}
      isUpload={isUpload}
      imgData={imgData}
      garageDoorDraw={garageDoorDraw}
      isMobileLandscapeView={isMobileLandscapeView}
      isMobileOS={isMobileOS}
      isResponsiveView={isResponsiveView}
      isIOSDevice={isIOSDevice}
      handleIsUpload={handleIsUpload}
      handleSetFileImg={handleSetFileImg}
      handleHouseSlideClick={handleHouseSlideClick}
      handleSlideClick={handleSlideClick}
      isDeviceOSModalVisible={isDeviceOSModalVisible}
      shapeType={shapeType}
      handleDeviceOSModalClick={handleDeviceOSModalClick}
      setToastrStatus={setToastrStatus}
      getCatalogData={getCatalogData}
      sendEmail={sendEmail}
      setLoaderVisibility={setLoaderVisibility}
      handleFilterApply={handleFilterApply}
      selectedCatalogImage={selectedCatalogImage}
      rotation={rotation}
      isFrontDoor={isFrontDoor}
      setSelectedDoordDetails={setSelectedDoordDetails}
      selectedDoorsDetails={selectedDoorsDetails}
      filterVariables={filterVariables}
      setIsInitialToaster={setIsInitialToaster}
      isInitialToaster={isInitialToaster}
      setting={setting}

    />
  ) : (
    <PortraitView
      houseData={houseData}
      catalogData={catalogData}
      selectedTypeData={catalogData}
      imgSrc={imgSrc}
      objectSrc={objectSrc}
      selectedCatalogName={selectedCatalogName}
      objectCoordinates={objectCoordinates}
      isUpload={isUpload}
      imgData={imgData}
      garageDoorDraw={garageDoorDraw}
      isMobileLandscapeView={isMobileLandscapeView}
      isMobileOS={isMobileOS}
      isResponsiveView={isResponsiveView}
      isIOSDevice={isIOSDevice}
      handleIsUpload={handleIsUpload}
      handleSetFileImg={handleSetFileImg}
      handleHouseSlideClick={handleHouseSlideClick}
      handleSlideClick={handleSlideClick}
      isDeviceOSModalVisible={isDeviceOSModalVisible}
      shapeType={shapeType}
      handleDeviceOSModalClick={handleDeviceOSModalClick}
      setToastrStatus={setToastrStatus}
      getCatalogData={getCatalogData}
      sendEmail={sendEmail}
      setLoaderVisibility={setLoaderVisibility}
      handleFilterApply={handleFilterApply}
      selectedCatalogImage={selectedCatalogImage}
      rotation={rotation}
      isFrontDoor={isFrontDoor}
      setSelectedDoordDetails={setSelectedDoordDetails}
      selectedDoorsDetails={selectedDoorsDetails}
      filterVariables={filterVariables}
      isInitialToaster={isInitialToaster}
      setIsInitialToaster={setIsInitialToaster}
      setting={setting}

    />
  );
};

export const DesktopView = ({
  houseData = [],
  catalogData = [],
  imgSrc,
  objectSrc,
  selectedCatalogName,
  objectCoordinates,
  isUpload,
  imgData,
  garageDoorDraw,
  handleIsUpload,
  handleSetFileImg,
  handleHouseSlideClick,
  handleSlideClick,
  isDeviceOSModalVisible,
  handleDeviceOSModalClick,
  isMobileLandscapeView,
  isMobileOS,
  isIOSDevice,
  isResponsiveView,
  selectedCatalogID,
  shapeType,
  handleFilterApply,
  setToastrStatus,
  getCatalogData,
  sendEmail,
  setLoaderVisibility,
  selectedTypeData,
  rotation,
  selectedCatalogImage,
  selectedCatalogImageData,
  isFrontDoor,
  setSelectedDoordDetails,
  selectedDoorsDetails,
  filterVariables,
  isInitialToaster,
  setIsInitialToaster,
  setting

}) => {
  return (
    <div className={classes.homeWrapperContainer}>
      <LandscapeView
        houseData={houseData}
        catalogData={catalogData}
        imgSrc={imgSrc}
        objectSrc={objectSrc}
        selectedCatalogName={selectedCatalogName}
        objectCoordinates={objectCoordinates}
        isUpload={isUpload}
        imgData={imgData}
        garageDoorDraw={garageDoorDraw}
        isMobileLandscapeView={isMobileLandscapeView}
        isMobileOS={isMobileOS}
        isResponsiveView={isResponsiveView}
        isIOSDevice={isIOSDevice}
        handleIsUpload={handleIsUpload}
        handleSetFileImg={handleSetFileImg}
        handleHouseSlideClick={handleHouseSlideClick}
        handleSlideClick={handleSlideClick}
        isDeviceOSModalVisible={isDeviceOSModalVisible}
        shapeType={shapeType}
        handleDeviceOSModalClick={handleDeviceOSModalClick}
        setToastrStatus={setToastrStatus}
        getCatalogData={getCatalogData}
        sendEmail={sendEmail}
        setLoaderVisibility={setLoaderVisibility}
        handleFilterApply={handleFilterApply}
        rotation={rotation}
        selectedCatalogImage={selectedCatalogImage}
        selectedCatalogImageData={selectedCatalogImageData}
        isFrontDoor={isFrontDoor}
        setSelectedDoordDetails={setSelectedDoordDetails}
        selectedDoorsDetails={selectedDoorsDetails}
        filterVariables={filterVariables}
        isInitialToaster={isInitialToaster}
        setIsInitialToaster={setIsInitialToaster}
        setting={setting}

      />
      {/* <div className={classes.promoContainer}>
        <div className={classes.promoHeader}>
          Try our free iOS app with more features
        </div>

        <img
          className={isFrontDoor ? classes.frontDoorGIF : " "}
          src={isFrontDoor ? setting?.door?.gif : setting?.garage?.gif}
        />

        <div className={classes.promoTextContainer}>
          <div>Additional Features</div>
          <ul>
            <li>Paint doors</li>
            <li>Before & after videos</li>
            <li>Edit & add doors</li>
            <li>Save favorites</li>
            <li>Easily share your ideas</li>
            <li>Replace doors at an angle</li>
          </ul>
        </div>
        <a
          href={isFrontDoor ? setting?.door?.appLink : setting?.garage?.appLink}
          target="_blank"
          rel="noopener"
        >
          <img src={AppStoreImg} className={classes.promoImg} />
        </a>
        <img src={isFrontDoor ? setting?.door?.qrcode : setting?.garage?.qrcode} className={classes.promoImg} />
        <img src={setting?.garage?.modifailogo} className={classes.promoImg} />

      </div> */}
    </div>
  );
};
