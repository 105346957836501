import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import AppIcon from "../../../assets/images/icon.png";
import { setBackStatus } from "../../../redux/common/actions";
import { setIsTransformationHappened } from "../../../redux/edit/actions";
import classes from "./Header.module.css";

const mapStateToProps = (state) => ({
  backStatus: state.common.backStatus,
  isBackBtnVisible: state.common.isBackBtnVisible,
  isMobileOS: state.common.isMobileOS,
  isMobileLandscapeView: state.common.isMobileLandscapeView,
  isTransformationHappened: state.edit.isTransformationHappened,
});

const mapDispatchToProps = (dispatch) => ({
  setBackStatus: (payload) => dispatch(setBackStatus(payload)),
  setIsTransformationHappened: (payload) =>
    dispatch(setIsTransformationHappened(payload)),
});

const HeaderComponent = React.memo((props) => {
  const {
    isMobileOS,
    isMobileLandscapeView,
    setBackStatus,
    isTransformationHappened,
    setIsTransformationHappened,
  } = props;

  const handleBack = () => {
    if (isTransformationHappened) {
      if (window.confirm("Changes made will not be saved. Are you sure you want to proceed with back?")) {
        setBackStatus(true);
        setIsTransformationHappened(false);
      }
    } else {
      setBackStatus(true);
    }
  };

  return (
    <div className={classes.headerWrapper}>
      <div
        className={`${classes.headerContainer} container ${
          isMobileOS ? classes.mobileOSHeader : ""
        } ${isMobileLandscapeView ? classes.mobileLandscapeHeader : ""}`}
      >
        <NavLink to="/" className={classes.flex}>
          <img
            src={AppIcon}
            className={`${classes.logo} ${isMobileOS ? classes.mobileOSLogo : ""}`}
            alt="ModifAI logo"
          />
        </NavLink>
        <Navbar className={classes.headerMain}>
          <Nav>
            <Nav.Link as={NavLink} exact to="/" className={classes.navlink} activeClassName={classes.active}>
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/modifai/exterior" className={classes.navlink} activeClassName={classes.active}>
              Exterior
            </Nav.Link>
            <Nav.Link as={NavLink} to="/modifai/interior" className={classes.navlink} activeClassName={classes.active}>
              Interior
            </Nav.Link>
            <Nav.Link as={NavLink} to="/modifai/garage" className={classes.navlink} activeClassName={classes.active}>
              Garage
            </Nav.Link>
            <Nav.Link as={NavLink} to="/modifai/frontdoor" className={classes.navlink} activeClassName={classes.active}>
              Door
            </Nav.Link>
            <Nav.Link href="https://modifai.com/" className={classes.navlink} target="_self" rel="noopener noreferrer">
              About
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
    </div>
  );
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);