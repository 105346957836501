import React, { useEffect, useState } from "react";
import InteriorSelectStyleComponent from "./InteriorSelectStyle.component";
import InteriorPreviewComponent from "./InteriorPreview.component";
import style from "./Interior.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setInteriorGeneratedImages, setToastrStatus } from "../../redux/common/actions";
import ToastrComponent from "../common/toastr/Toastr.component";

const InteriorComponent = () => {
  const [interiorDetails, setInteriorDetails] = useState({
    file: null,
    step: "upload",
    style: "",
    generated: [],
    loading: false,
    recentlyGenerated: {},
  });
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const onSelectStyle = (style) => {
    setInteriorDetails({
      ...interiorDetails,
      style,
    });
  };

  const onFileUpload = (file) => {
    setLoading(false);
    setInteriorDetails({
      ...interiorDetails,
      file,
      step: "preview",
    });
  };

  const dispatch = useDispatch();
  const generatedImages = useSelector((state) => state.common.interiorGeneratedImages);

  useEffect(() => {
    setIsMounted(true);
    if (generatedImages) setInteriorDetails(generatedImages);
    return () => {
      setIsMounted(false);
    };
  }, [generatedImages]);

  const onGenerate = async () => {
    setLoading(true);
    let recentlyGeneratedResponse = {
      style: interiorDetails.style,
      response: [
        { src: URL.createObjectURL(interiorDetails.file) },
        { src: null },
        { src: null },
        { src: null },
      ],
    };

    setInteriorDetails({
      ...interiorDetails,
      recentlyGenerated: recentlyGeneratedResponse,
      step: "generated",
    });

    const formData = new FormData();
    formData.append("image", interiorDetails.file);
    formData.append("housetype", interiorDetails.style);
    formData.append("inout", "interior");

    const controller = new AbortController();
    const id = setTimeout(() => {
      controller.abort();
    }, 120000);

    try {
      const response = await fetch("https://exteriormodifai.com/api/upload", {
        method: "POST",
        body: formData,
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let data = "";
      let done = false;
      const urlsReceived = new Set();

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        data += decoder.decode(value, { stream: true });

        // Split the data into separate JSON objects
        const jsonObjects = data.split('\n').filter(obj => obj.trim() !== '');

        jsonObjects.forEach(obj => {
          try {
            // Remove the 'data:' prefix if it exists
            if (obj.startsWith('data:')) {
              obj = obj.slice(5);
            }
            const jsonData = JSON.parse(obj);
            console.log("JSON data received:", jsonData);

            if (jsonData.original_image_url && !urlsReceived.has(jsonData.original_image_url)) {
              recentlyGeneratedResponse = {
                style: interiorDetails.style,
                response: [
                  { src: jsonData.original_image_url },
                  { src: null },
                  { src: null },
                  { src: null },
                ],
              };
              urlsReceived.add(jsonData.original_image_url);
              if (isMounted) {
                setInteriorDetails({
                  ...interiorDetails,
                  recentlyGenerated: recentlyGeneratedResponse,
                  step: "generated",
                });
              }
            } else {
              if (jsonData.generated_image_url_1 && !urlsReceived.has(jsonData.generated_image_url_1)) {
                recentlyGeneratedResponse.response[1].src = jsonData.generated_image_url_1;
                urlsReceived.add(jsonData.generated_image_url_1);
              }
              if (jsonData.generated_image_url_2 && !urlsReceived.has(jsonData.generated_image_url_2)) {
                recentlyGeneratedResponse.response[2].src = jsonData.generated_image_url_2;
                urlsReceived.add(jsonData.generated_image_url_2);
              }
              if (jsonData.generated_image_url_3 && !urlsReceived.has(jsonData.generated_image_url_3)) {
                recentlyGeneratedResponse.response[3].src = jsonData.generated_image_url_3;
                urlsReceived.add(jsonData.generated_image_url_3);
              }
              if (isMounted) {
                setInteriorDetails({
                  ...interiorDetails,
                  recentlyGenerated: recentlyGeneratedResponse,
                  step: "generated",
                });
              }
            }
          } catch (e) {
            console.error("Failed to parse JSON:", obj);
          }
        });
      }

      if (isMounted) {
        setInteriorDetails({
          ...interiorDetails,
          generated: [recentlyGeneratedResponse, ...interiorDetails.generated],
          recentlyGenerated: recentlyGeneratedResponse,
          step: "generated",
          loading: false,
        });

        dispatch(setInteriorGeneratedImages({
          ...interiorDetails,
          generated: [recentlyGeneratedResponse, ...interiorDetails.generated],
          recentlyGenerated: recentlyGeneratedResponse,
          step: "generated",
          loading: false,
        }));
      }

    } catch (error) {
      console.error("Error:", error);
      if (isMounted) {
        dispatch(setToastrStatus({
          visible: true,
          type: "error",
          message: "Sorry, something went wrong. The interiors experience is still in beta, so from time to time this can happen. Can you please try again.",
        }));
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
      clearTimeout(id);
    }
  };

  return (
    <div className={style.interiorcomp}>
      <ToastrComponent />
      {interiorDetails.step !== "generated" && (
        <InteriorSelectStyleComponent
          onSelectStyle={onSelectStyle}
          onFileUpload={onFileUpload}
          interiorDetails={interiorDetails}
          onGenerate={onGenerate}
          setInteriorDetails={setInteriorDetails}
          loading={loading}
        />
      )}

      {interiorDetails.step === "generated" && (
        <InteriorPreviewComponent
          onSelectStyle={onSelectStyle}
          interiorDetails={interiorDetails}
          onGenerate={onGenerate}
          setInteriorDetails={setInteriorDetails}
          loading={loading}
        />
      )}
    </div>
  );
};

export default InteriorComponent;
