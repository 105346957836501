export const SINGLE_DOOR = "front door:single";
export const DOUBLE_DOOR = "front door:double";

export const SINGLE_DOOR_GARAGE = "garage:one-car";
export const DOUBLE_DOOR_GARAGE = "garage:two-car";

export const HOME_ITEMS_GARAGE = [
  {
    name: "House 1",
    imgPath: require("../garage_house1.jpg"),
    class_ids: [2],
    coordinates: [
      [
        [333, 836],
        [886, 836],
        [886, 1111],
        [333, 1111],
      ],
    ],
    classIDs: [2],
    canvasDrawData: [
      {
        classId: 2,
        labels: ["garage:two-car"],
        coordinates: [
          [
            [333, 836],
            [886, 836],
            [886, 1111],
            [333, 1111],
          ],
        ],
      },
    ],
    dimensions: { x: 2048, y: 1365 },
  },
  // ... other HOME_ITEMS_GARAGE entries ...
];

export const HOME_ITEMS_FRONT = [
  {
    name: "House 2",
    imgPath: require("../frontdoor_house2.jpeg"),
    class_ids: [2],
    coordinates: [
      [
        [1087, 802],
        [1198, 802],
        [1198, 1070],
        [1087, 1070],
      ],
    ],
    classIDs: [2],
    canvasDrawData: [
      {
        classId: 2,
        labels: ["front door:single"],
        coordinates: [
          [
            [1087, 802],
            [1198, 802],
            [1198, 1070],
            [1087, 1070],
          ],
        ],
      },
    ],
    dimensions: { x: 2048, y: 1328 },
  },
  // ... other HOME_ITEMS_FRONT entries ...
];

export const CLASS_ID_MAPPER = {
  1: "one_car_garage",
  2: "two_car_garage",
  3: "front_door",
};

export const SAMPLE_CATALOG = {
  leadingText: "All about",
  slides: [
    // ... SAMPLE_CATALOG slides ...
  ],
};

export const EXTERIOR_STYLES = [
  { label: "Coastal", value: "Coastal" },
  { label: "Coastal Modern", value: "Coastal Modern" },
  { label: "Contemporary", value: "Contemporary" },
  { label: "Craftsman", value: "Craftsman" },
  { label: "Mid Century Modern", value: "Mid Century Modern" },
  { label: "Modern Farmhouse", value: "Modern Farmhouse" },
  { label: "Mountain Modern", value: "Mountain Modern" },
  { label: "Scandinavian", value: "Scandinavian" },  
  { label: "Southwestern", value: "Southwestern" }, 
];

export const EXTERIOR_COLORS = [
  { label: "Black", value: "Black" },
  { label: "White", value: "White" },
  { label: "Grey", value: "Grey" },
  { label: "Blue", value: "Blue" },
  { label: "Beige", value: "Beige" },
  { label: "Red", value: "Red" },
];

export const INTERIOR_STYLES = [
  { label: "Coastal", value: "Coastal" },
  { label: "Contemporary", value: "Contemporary" },
  { label: "Glam", value: "Glam" },
  { label: "Industrial", value: "Industrial" },
  { label: "Mediterranean", value: "Mediterranean" },
  { label: "Mid-Century Modern", value: "Mid-Century Modern" },
  { label: "Minimalist", value: "Minimalist" },
  { label: "Modern", value: "Modern" },  
  { label: "Modern Farmhouse", value: "Modern Farmhouse" },
  { label: "Mountain Modern", value: "Mountain Modern" },
  { label: "Rustic", value: "Rustic" },
  { label: "Scandinavian", value: "Scandinavian" },
  { label: "Traditional", value: "Traditional" },
  { label: "Transitional", value: "Transitional" },
];

export const INTERIOR_COLORS = [
  { label: "White", value: "White" },
  { label: "Gray", value: "Gray" },
  { label: "Beige", value: "Beige" },
  { label: "Blue", value: "Blue" },
  { label: "Green", value: "Green" },
  { label: "Yellow", value: "Yellow" },
  { label: "Orange", value: "Orange" },
  { label: "Red", value: "Red" },
  { label: "Purple", value: "Purple" },
  { label: "Brown", value: "Brown" },
];

export const defaultSetting = {
  colors: ["--primary:#1C224B", "--secondary:blue", "--tertiary:pink"],
  door: {
    appLink:
      "https://apps.apple.com/us/app/door-visualizer-with-modifai/id1459977254",
    appName: "Modifai Front Door",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  garage: {
    appLink: "https://apps.apple.com/us/app/modifai-garage/id1530293593",
    appName: "ModifAI Garage",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  exterior: {
    appLink: "https://apps.apple.com/us/app/modifai-garage/id1530293593",
    appName: "ModifAI Garage",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  interior: {
    appLink: "https://apps.apple.com/us/app/modifai-interior/id1234567890", // Update with actual app link
    appName: "ModifAI Interior",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Interior-Demo.gif", // Update with actual GIF
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/interior_logo.png", // Update with actual logo
    modifailogo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode: "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai_interior_QR_code.png", // Update with actual QR code
  },
  route: "modifai",
  supplierordealer: "dealer",
};