import React, { useEffect, useRef, useState } from "react";
import Swiper from 'react-id-swiper';
import { Scrollbars } from 'react-custom-scrollbars';
import WatermarkLogo from '../../../assets/images/watermark.png';
import {isMobileDevice, isLandscapeScreen} from '../../../utils';
import classes from "./Swiper.module.css";
import 'pure-swipe';
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

const isMobileLandscapeView = isMobileDevice() && isLandscapeScreen();

export const SwiperComponent = ({ data = [], selected, title = '', handleSlideClick, isHomeSwipper = false, isResponsiveView,isFrontDoor=false, setSelectedDoordDetails }) => {
  const params = {
    direction: !isResponsiveView || isMobileLandscapeView ? 'vertical' : 'horizontal',
    ...(!isResponsiveView && {
        slidesPerView: 100,
    }),
    noSwiping: !isResponsiveView,
    speed: 600,
    parallax: true,
    mousewheel: true,
}  
  const swiperRef = useRef(null);
    const slideClick = (selected) => {
        selected.url_original && setSelectedDoordDetails(selected);
        const { url_original, imgPath, coordinates, class_ids, classIDs, name, canvasDrawData, shapeType, rotation, catalogID, dimensions } = selected;
        handleSlideClick({ imgPath: url_original || imgPath.default, coordinates, class_ids, name, classIDs, canvasDrawData, shapeType, rotation, catalogID, dimensions });
    };

    const handleSwiperNext = () => {
        if (swiperRef && swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    }

    const handleSwiperPrev = () => {
        if (swiperRef && swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    return (
        <div className={` ${classes.swiperWrapper} ${isHomeSwipper && classes.homeSwiperWrapper} ${isResponsiveView && classes.responsiveSwipper} swiper-container`}>
            <Swiper {...params} ref={swiperRef}>
                {data.map((door) => (
                    <div key={door.catalogID || door.name} className={`${classes.swiperSlide} ${isResponsiveView && classes.responsiveSwipperSlide} swiper-slide`}>
                        <div
                            className={`${isFrontDoor && classes.frontdoorCatelogImg} ${classes.swiperImgContainer} ${classes.fullWidth}`}
                            onClick={slideClick.bind(null, door)}
                        >
                            <img className={classes.swiperImg}
                                src={door.url_original || door.imgPath.default}
                                loading="lazy"
                            />
                        </div>
                    </div>
                ))}
            </Swiper>
            {!isResponsiveView && (
                <div className={classes.btnContainer}>
                    <button className={classes.prevBtn} onClick={handleSwiperPrev}>&#9650;</button>
                    <button className={classes.nextBtn} onClick={handleSwiperNext}>&#9660;</button>
                </div>
            )}
        </div>
    );
};

export const SwiperComp = ({ data = [], id, isHouseImg = false, handleSlideClick, isMobileOS, selectedCatalogID, isFrontDoor = false, setSelectedDoordDetails }) => {
    const [top, setTop] = useState(0);
    const [catalogIndex, setCatalogIndex] = useState(0);
    const [ intervalTO, setIntervalTO ] = useState(null);
    const doorDOM = document.getElementById(id);

    useEffect(() => {
        document.getElementById(id).addEventListener('swiped-up', function(e) {
            document.getElementById(id).scrollBy({ top: 150, left: 0, behavior: 'smooth'});
        });

        document.getElementById(id).addEventListener('swiped-down', function(e) {
            document.getElementById(id).scrollBy({ top: -150, left: 0, behavior: 'smooth'});
        });

        const nextBtn = `${id}-long-press-next-btn`;
        const nextBtnElm = document.getElementById(nextBtn);
        nextBtnElm && nextBtnElm.addEventListener('long-press', (e) => {
            e.preventDefault();
            slideImageContainer(true);
        });

        const prevBtn = `${id}-long-press-prev-btn`;
        const prevBtnElm = document.getElementById(prevBtn);
        prevBtnElm && prevBtnElm.addEventListener('long-press', (e) => {
            e.preventDefault();
            slideImageContainer();
        });
    }, []);

    useEffect(() => {
        doorDOM && doorDOM.scrollTo({ top, left: 0, behavior: 'smooth' });
    }, [top]);

    useEffect(() => {
      const dom = document.getElementById(`${id}-${catalogIndex}`);
        dom && dom.scrollIntoView({ behavior: 'smooth' });
    }, [catalogIndex]);

    const slideImageContainer = (isNext) => {
        const dom = document.getElementById(id);
        const intervalId = setInterval(() => {
            isNext ? dom.scrollBy({ top: 150, left: 0, behavior: 'smooth' }) : dom.scrollBy({ top: -150, left: 0, behavior: 'smooth' });
        }, 100);
        setIntervalTO(intervalId);
    }

    const slideClick = (selected) => {
        selected.url_original && setSelectedDoordDetails(selected);
        const { url_original, imgPath, coordinates, class_ids, classIDs, name, canvasDrawData, shapeType, rotation, catalogID, dimensions, base64Path } = selected;
        handleSlideClick({ imgPath: url_original || imgPath.default, coordinates, class_ids, name, classIDs, canvasDrawData, shapeType, rotation, catalogID, dimensions, base64Path });
    };

    return (
        <div className={classes.swiperComWrapperContainer}>
            <Scrollbars className={`${classes.swiperComWrapper} ${isHouseImg && classes.houseImgWrapper} ${isMobileLandscapeView && isHouseImg && classes.houseImgWrapperLandscape} ${selectedCatalogID === 1 && classes.singleDoorImgWrapper}`} id={id}
                data-swipe-timeout="10"
            >
                {data.map((door, index) => {
                    return (
                    <div className={`${isFrontDoor && classes.frontdoorCatelogImg} ${classes.swiperItemContainer} ${isMobileLandscapeView && classes.mobileSwiperItem} ${isHouseImg && classes.houseImgHeight}`} key={door.catalogID || door.name} 
                        onClick={slideClick.bind(null, door)}
                        id={`${id}-${index}`}
                    >
                        <img className={`${classes.swiperImg} ${!isHouseImg && 'catalog-img'}`} 
                            src={door.url_original || door.imgPath.default}
                            loading="lazy"
                        />
                        {!isHouseImg && <img className={classes.watermark} src={WatermarkLogo} />}
                    </div>
                )})}
            </Scrollbars>
        </div>
    );
}
