import { EDIT_ACTIONS } from "./actionTypes";

/** Initial state for home reducer */
const initialState = {
  selectedHouseImage: null,
  selectedCatalogImage: null,
  selectedCatalogImageDimensions: [],
  selectedCatalogImageData: {},
  isTransformationHappened: false,
};

/**
 * Function handing/responsible for updating home component's state
 * @param {object} state
 * @param {object} action
 */
export const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ACTIONS.GET_SELECTED_HOME_IMAGE:
      return {
        ...state,
        selectedHouseImage: action.payload,
      };
    case EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR:
      return {
        ...state,
        selectedCatalogImage: action.payload,
      };
    case EDIT_ACTIONS.RESET_SELECTED_CATALOG_DOOR:
      return {
        ...state,
        selectedCatalogImage: null,
      };
    case EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR_DIMENSIONS:
      return {
        ...state,
        selectedCatalogImageDimensions: action.payload,
      };
    case EDIT_ACTIONS.SET_SELECTED_CATALOG_DOOR_DATA:
      return {
        ...state,
        selectedCatalogImageData: action.payload,
      };
    case EDIT_ACTIONS.SET_IS_TRANSFORMATION_HAPPENED:
      return {
        ...state,
        isTransformationHappened: action.payload,
      };
    default:
      return state;
  }
};
