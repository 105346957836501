export const HOME_ACTIONS = {
  GET_CATALOG_DATA: "GET_CATALOG_DATA",
  SET_CATALOG_DATA: "SET_CATALOG_DATA",
  SET_SELECTED_TYPE_ID: "SET_SELECTED_TYPE_ID",
  SET_SELECTED_TYPE_DATA: "SET_SELECTED_TYPE_DATA",
  SET_SELECTED_TYPE_ID_ALL: "SET_SELECTED_TYPE_ID_ALL",
  SET_SELECTED_CATALOG_ID: "SET_SELECTED_CATALOG_ID",
  SET_SELECTED_CATALOG_ITEM_DETAILS: "SET_SELECTED_CATALOG_ITEM_DETAILS",
  GET_FILTER_CATALOG_DATA: "GET_FILTER_CATALOG_DATA",
  SET_FILTER_CATALOG_DATA: "SET_FILTER_CATALOG_DATA",
  SEND_EMAIL: "SEND_EMAIL",
  SET_EDIT_HOUSE_IMAGE: "SET_EDIT_HOUSE_IMAGE",
  RESET_CATALOG_DATA: "RESET_CATALOG_DATA",
  SET_FILTER_VALUES: "SET_FILTER_VALUES",
  SET_SELECTED_DOORS_DETAILS: "SET_SELECTED_DOORS_DETAILS",

};
