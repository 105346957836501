import React, { useEffect, useState } from "react";
import SelectStyleComponent from "./SelectStyle.component";
import PreviewComponent from "./Preview.component";
import style from "./Exterior.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setExterierGeneratedImages, setToastrStatus } from "../../redux/common/actions";
import ToastrComponent from "../common/toastr/Toastr.component";

const ExteriorComponent = () => {
  const [exteriorDetails, setExteriorDetails] = useState({
    file: null,
    step: "upload",
    style: "",
    generated: [],
    loading: false,
    recentlyGenerated: {},
  });
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const onSelectStyle = (style) => {
    setExteriorDetails({
      ...exteriorDetails,
      style,
    });
  };

  const onFileUpload = (file) => {
    setLoading(false);
    setExteriorDetails({
      ...exteriorDetails,
      file,
      step: "preview",
    });
  };

  const dispatch = useDispatch();
  const generatedImages = useSelector((state) => state.common.exteriorGeneratedImages);

  useEffect(() => {
    setIsMounted(true);
    if (generatedImages) setExteriorDetails(generatedImages);
    return () => {
      setIsMounted(false);
    };
  }, [generatedImages]);

  const onGenerate = async () => {
    setLoading(true);
    let recentlyGeneratedResponse = {
      style: exteriorDetails.style,
      response: [
        { src: URL.createObjectURL(exteriorDetails.file) },
        { src: null },
        { src: null },
        { src: null },
      ],
    };

    setExteriorDetails({
      ...exteriorDetails,
      recentlyGenerated: recentlyGeneratedResponse,
      step: "generated",
    });

    const formData = new FormData();
    formData.append("image", exteriorDetails.file);
    formData.append("housetype", exteriorDetails.style);
    formData.append("inout", "exterior");

    const controller = new AbortController();
    const id = setTimeout(() => {
      controller.abort();
    }, 75000);

    try {
      const response = await fetch("https://exteriormodifai.com/api/upload", {
        method: "POST",
        body: formData,
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let data = "";
      let done = false;
      const urlsReceived = new Set();

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        data += decoder.decode(value, { stream: true });

        // Split the data into separate JSON objects
        const jsonObjects = data.split('\n').filter(obj => obj.trim() !== '');

        jsonObjects.forEach(obj => {
          try {
            // Remove the 'data:' prefix if it exists
            if (obj.startsWith('data:')) {
              obj = obj.slice(5);
            }
            const jsonData = JSON.parse(obj);
            console.log("JSON data received:", jsonData);

            if (jsonData.original_image_url && !urlsReceived.has(jsonData.original_image_url)) {
              recentlyGeneratedResponse = {
                style: exteriorDetails.style,
                response: [
                  { src: jsonData.original_image_url },
                  { src: null },
                  { src: null },
                  { src: null },
                ],
              };
              urlsReceived.add(jsonData.original_image_url);
              if (isMounted) {
                setExteriorDetails({
                  ...exteriorDetails,
                  recentlyGenerated: recentlyGeneratedResponse,
                  step: "generated",
                });
              }
            } else {
              if (jsonData.generated_image_url_1 && !urlsReceived.has(jsonData.generated_image_url_1)) {
                recentlyGeneratedResponse.response[1].src = jsonData.generated_image_url_1;
                urlsReceived.add(jsonData.generated_image_url_1);
              }
              if (jsonData.generated_image_url_2 && !urlsReceived.has(jsonData.generated_image_url_2)) {
                recentlyGeneratedResponse.response[2].src = jsonData.generated_image_url_2;
                urlsReceived.add(jsonData.generated_image_url_2);
              }
              if (jsonData.generated_image_url_3 && !urlsReceived.has(jsonData.generated_image_url_3)) {
                recentlyGeneratedResponse.response[3].src = jsonData.generated_image_url_3;
                urlsReceived.add(jsonData.generated_image_url_3);
              }
              if (isMounted) {
                setExteriorDetails({
                  ...exteriorDetails,
                  recentlyGenerated: recentlyGeneratedResponse,
                  step: "generated",
                });
              }
            }
          } catch (e) {
            console.error("Failed to parse JSON:", obj);
          }
        });
      }

      if (isMounted) {
        setExteriorDetails({
          ...exteriorDetails,
          generated: [recentlyGeneratedResponse, ...exteriorDetails.generated],
          recentlyGenerated: recentlyGeneratedResponse,
          step: "generated",
          loading: false,
        });

        dispatch(setExterierGeneratedImages({
          ...exteriorDetails,
          generated: [recentlyGeneratedResponse, ...exteriorDetails.generated],
          recentlyGenerated: recentlyGeneratedResponse,
          step: "generated",
          loading: false,
        }));
      }

    } catch (error) {
      console.error("Error:", error);
      if (isMounted) {
        dispatch(setToastrStatus({
          visible: true,
          type: "error",
          message: "Sorry, something went wrong. Can you please try again.",
        }));
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
      clearTimeout(id);
    }
  };

  return (
    <div className={style.exteriorcomp}>
      <ToastrComponent />
      {exteriorDetails.step !== "generated" && (
        <SelectStyleComponent
          onSelectStyle={onSelectStyle}
          onFileUpload={onFileUpload}
          exteriorDetails={exteriorDetails}
          onGenerate={onGenerate}
          setExteriorDetails={setExteriorDetails}
          loading={loading}
        />
      )}

      {exteriorDetails.step === "generated" && (
        <PreviewComponent
          onSelectStyle={onSelectStyle}
          exteriorDetails={exteriorDetails}
          onGenerate={onGenerate}
          setExteriorDetails={setExteriorDetails}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ExteriorComponent;

