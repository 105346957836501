import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { configStore } from "./redux/store";
import EditContainer from "./components/edit/Edit.container";
import HeaderComponent from "./components/common/header/Header.component";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import FrontdoorComponent from "./components/frontdoor/Frontdoor.container";
import GarageComponent from "./components/garage/Garage.container";
import HomeContainer from "./components/home/Home.container";
import ExteriorComponent from "./components/exterior/Exterior.component";
import InteriorComponent from "./components/interior/Interior.component";
import ReactGA from 'react-ga4';

import { getEntryPoints } from "./services/Home.service";

const defaultSetting = {
  colors: ["--primary:#1C224B", "--secondary:blue", "--tertiary:pink"],
  door: {
    appLink:
      "https://apps.apple.com/us/app/door-visualizer-with-modifai/id1459977254",
    appName: "Modifai Front Door",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  garage: {
    appLink: "https://apps.apple.com/us/app/modifai-garage/id1530293593",
    appName: "ModifAI Garage",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  exterior: {
    appLink: "https://apps.apple.com/us/app/modifai-garage/id1530293593",
    appName: "ModifAI Garage",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Garage-Demo-March+(1).gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(12).png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai+garage+QR+code.png",
  },
  interior: {
    appLink: "https://apps.apple.com/us/app/modifai-interior/id1234567890",
    appName: "ModifAI Interior",
    catalog: "https://prodappmodifai.link/GetCatalog/all_home_objects",
    gif: "https://logosmodifai.s3.us-west-2.amazonaws.com/Interior-Demo.gif",
    logo: "https://logosmodifai.s3.us-west-2.amazonaws.com/interior_logo.png",
    modifailogo:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/image+(11).png",
    proposal: "https://prodappmodifai.link/CreateWebUserProposal",
    qrcode:
      "https://logosmodifai.s3.us-west-2.amazonaws.com/modifai_interior_QR_code.png",
  },
  route: "modifai",
  supplierordealer: "dealer",
};

const store = configStore();
ReactGA.initialize('G-L19RDCT5C2');

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const [setting, setSetting] = useState(null);
  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    const pathname = window.location.hash.split("/");
    getEntryPoints().then((response) => {
      if (pathname.length >= 2 && response.data[pathname[1]]) {
        setSetting(response.data[pathname[1]]);
      } else {
        setSetting(defaultSetting);
        window.location.pathname !== "/#/" && window.location.replace("/#/");
      }
    });
  }, []);

  useEffect(() => {
    if (setting) {
      const parentContainer = document.getElementById("parentContainer");
      parentContainer.style.cssText = setting.colors.join(";");
    }
  }, [setting]);

  if (!setting) {
    return <></>;
  }

  return (
    <div className="app-container" id="parentContainer">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/">
              <HomeContainer setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/exterior`}>
              <HeaderComponent page="exterior" setting={setting} />
              <ExteriorComponent setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/interior`}>
              <HeaderComponent page="interior" setting={setting} />
              <InteriorComponent setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/garage`}>
              <HeaderComponent page="garage" setting={setting} />
              <GarageComponent setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/frontdoor`}>
              <HeaderComponent page="door" setting={setting} />
              <FrontdoorComponent setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/edit-garage`}>
              <HeaderComponent page="garage" setting={setting} />
              <EditContainer setting={setting} />
            </Route>
            <Route exact path={`/${setting.route}/edit-door`}>
              <HeaderComponent page="door" setting={setting} />
              <EditContainer setting={setting} />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;