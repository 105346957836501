export const EDIT_ACTIONS = {
  GET_SELECTED_HOME_IMAGE: "GET_SELECTED_HOME_IMAGE",
  SET_SELECTED_CATALOG_DOOR: "SET_SELECTED_CATALOG_DOOR",
  RESET_SELECTED_CATALOG_DOOR: "RESET_SELECTED_CATALOG_DOOR",
  SUCCESS_SET_SELECTED_CATALOG_DOOR: "SUCCESS_SET_SELECTED_CATALOG_DOOR",
  SET_SELECTED_CATALOG_DOOR_DIMENSIONS: "SET_SELECTED_CATALOG_DOOR_DIMENSIONS",
  SUCCESS_SET_SELECTED_CATALOG_DOOR_DIMENSIONS:
    "SUCCESS_SET_SELECTED_CATALOG_DOOR_DIMENSIONS",
  SET_SELECTED_CATALOG_DOOR_DATA: "SET_SELECTED_CATALOG_DOOR_DATA",
  SET_IS_TRANSFORMATION_HAPPENED: "SET_IS_TRANSFORMATION_HAPPENED",
};
