import { truncate } from 'lodash';
import React from 'react';
import classes from './Button.module.css';

export const PrimaryButton = ({ title, type, isDisabled = true, handleClick, cssStyle }) => {
    return (
        <button {...type} className={`${classes.primaryBtn} ${cssStyle}`} onClick={handleClick} disabled={!isDisabled}
        >{title}</button>
    )
}

export const SecondaryButton = ({ title, handleClick }) => {
	return (
		<button className={classes.secondaryBtn} onClick={handleClick}>{title}</button>
	)
}