import * as _ from "lodash";
import { CLASS_ID_MAPPER, SINGLE_DOOR, DOUBLE_DOOR, SINGLE_DOOR_GARAGE, DOUBLE_DOOR_GARAGE } from "../constants/data";

export const getCanvasImageData = (coordinates = []) => {
  const coordinatesLength = _.get(coordinates, "[0].length", 0);

  if (coordinatesLength === 6) {
    const coordinatesData = coordinates.map((item) => {
      const xCoord = item[0] ? item[0][0] : 0;
      const yCoord = item[1] ? item[1][1] : 0;
      const width = item[3][0] - xCoord;
      const height = item[4][1] - yCoord;

      return {
        xCoord,
        yCoord,
        width,
        height,
        coordinates:coordinates
      };
    });

    return coordinatesData;
  } else {
    const coordinatesData = coordinates.map((item) => {
      const xCoord = item[0] ? item[0][0] : 0;
      const yCoord = item[0] ? item[0][1] : 0;
      const width = item[1] && item[1][0] ? item[1][0] - xCoord : 0;
      const height = item[3] && item[3][1] ? item[3][1] - yCoord : 0;

      return {
        xCoord,
        yCoord,
        width,
        height,
        coordinates:coordinates
      };
    });

    return coordinatesData;
  }
};

const getClassId = (doorType) => {
  switch(doorType) {
    case SINGLE_DOOR_GARAGE: 
    return 1;

    case DOUBLE_DOOR_GARAGE: 
    return 2;

    case SINGLE_DOOR: 
    return 3

    case DOUBLE_DOOR: 
    return 4
  }
}

export const getAddDoorData = (doorType, doorStyle, selectedDoorsDetails) => {
  console.log(getClassId(doorType), (getClassId(doorType) === 1 || 2), (getClassId(doorType) === 1 || 2) ? 172 : 86);
  const width = 400;
  const height = 400;
  if(doorStyle === 'dutch_corner') {
    const item = {
      classId: getClassId(doorType),
      coordinates: [
        [
          [2048/2-width/2, 1350/2-height/2],
          [2048/2-width/2 + 60, 1350/2-height/2-60],
          [2048/2+width/2-60, 1350/2-height/2-60],
          [2048/2+width/2, 1350/2-height/2],
          [2048/2+width/2, 1350/2+height/2],
          [2048/2-width/2, 1350/2+height/2],
        ],
      ],
      coordinatesToPaint: {
        catalogID: `${new Date().getTime()}${doorStyle}`,
        height:400,
        width: 400,
        xCoord: (2040 - 400)/2,
        yCoord: (1350 - 400)/2,
        imgPath: (getClassId(doorType) === 1 || getClassId(doorType) === 3) ? (selectedDoorsDetails?.single?.url_original || selectedDoorsDetails?.double?.url_original) : (selectedDoorsDetails?.double?.url_original || selectedDoorsDetails?.single?.url_original)
      },
      labels: [doorType],
      imgPath: (getClassId(doorType) === 1 || getClassId(doorType) === 3) ? (selectedDoorsDetails?.single?.url_original || selectedDoorsDetails?.double?.url_original) : (selectedDoorsDetails?.double?.url_original || selectedDoorsDetails?.single?.url_original)
    };
    return item;
  } else {
    const item = {
      classId: getClassId(doorType),
      coordinates: [
        [
          [2048/2-width/2, 1350/2-height/2],
          [2048/2+width/2, 1350/2-height/2],
          [2048/2+width/2, 1350/2+height/2],
          [2048/2-width/2, 1350/2+height/2],
        ],
      ],
      coordinatesToPaint: {
        catalogID: `${new Date().getTime()}${doorStyle}`,
        height:400,
        width: 400,
        xCoord: (2040 - 400)/2,
        yCoord: (1350 - 400)/2,
        imgPath: (getClassId(doorType) === 1 || getClassId(doorType) === 3) ? (selectedDoorsDetails?.single?.url_original || selectedDoorsDetails?.double?.url_original) : (selectedDoorsDetails?.double?.url_original || selectedDoorsDetails?.single?.url_original)
      },
      labels: [doorType],
      imgPath: (getClassId(doorType) === 1 || getClassId(doorType) === 3) ? (selectedDoorsDetails?.single?.url_original || selectedDoorsDetails?.double?.url_original) : (selectedDoorsDetails?.double?.url_original || selectedDoorsDetails?.single?.url_original)
    };
    return item;
  }
  
};

export const dataURItoBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const getUploadedHouseImageData = (uploadData, page) => {
  const doorList = _.get(uploadData, "data.response", []);
  let isValidResponse = true;
  if (!doorList.length) isValidResponse = false
  for (let i = 0; i < doorList.length; i++) {
    let item = doorList[i];
    if (page === 'garage') {
      isValidResponse = (item[0].labels.includes(SINGLE_DOOR_GARAGE) || item[0].labels.includes
      (DOUBLE_DOOR_GARAGE))
      if (isValidResponse) {
        return  handleUploadROIS(doorList,SINGLE_DOOR_GARAGE,DOUBLE_DOOR_GARAGE);
        // break
      }
    } else if (page === 'frontdoor') {
      isValidResponse = (item[0].labels.includes(SINGLE_DOOR) || item[0].labels.includes(DOUBLE_DOOR))
      return  handleUploadROIS(doorList,SINGLE_DOOR,DOUBLE_DOOR);
      // break
    }
  }

  if(!isValidResponse) return []
};

const handleUploadROIS = (doorList, single, double) => {
  const filterDoorList = doorList.filter(
    (item) =>
      item[0].labels.includes(single) || item[0].labels.includes(double) 
  );
  const roisDataList = filterDoorList.map((item) => {
    return item[0].rois;
  });

  // const coordinates = _.get(uploadData, 'data.response[0][0].rois', []);
  return roisDataList;
}

export const getDisplayClassID = (classIDs) => {
  const uniqueArray = [...new Set(classIDs)];
  if (uniqueArray.length && uniqueArray.length === 1) {
    return uniqueArray[0];
  } else {
    return 2 || Math.max(...uniqueArray);
  }
};

export const getDoorImageForGarageType = (catalogData, name, typeID) => {
  const doorImage = catalogData[CLASS_ID_MAPPER[typeID]] && catalogData[CLASS_ID_MAPPER[typeID]].filter((item) => {
    return item.name === name;
  });

  return doorImage?.length ? doorImage[0]["url_original"] : null;
};

export const getImageBase64 = (data) => {
  const catalogDataset = data.map((item) => {
    // var img = new Image();
    // img.src = item.url_original;
    // // onload fires when the image is fully loadded, and has width and height

    // img.onload = function(){
    //   var canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   var ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0);
    //   var dataURL = canvas.toDataURL("image/png"),
    //       dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

    //   // callback(dataURL); // the base64 string
    //   item.local_url = dataURL;
    // };

    return item;
  });

  // set attributes and src
  // img.setAttribute('crossOrigin', 'anonymous'); //
  // img.src = imgUrl;
};

export const getDeviceOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "windows";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod|Mac OS/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "unknown";
};

export const isMobileDevice = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const isIPadDevice = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPod/i.test(userAgent);
};

export const isLandscapeScreen = () => {
  return window.matchMedia("(orientation: landscape)").matches ? true : false;
  // return document.body.clientWidth > document.body.clientHeight ? true : false;
};

export const setScreenOrientationChangeData = () => {
  const isResponsiveView = document.body.clientWidth < 1025;
  const isMobileOS = isMobileDevice();
  const isLandscape = isLandscapeScreen();
  const isMobileLandscapeView = isMobileDevice() && isLandscapeScreen();
  return {
    isResponsiveView,
    isMobileOS,
    isLandscape,
    isMobileLandscapeView,
  };
};

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
export function getUserIP(onNewIP) {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: [],
    }),
    noop = function () {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split("\n").forEach(function (line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

// Usage

// getUserIP(function(ip){
//     alert("Got IP! :" + ip);
// });

//
export const setRotationThresholdValue = (rotation) => {
  const calculation = rotation * Math.PI;
  if (rotation < 0) {
    return calculation / 360 + (calculation / 360 > -0.6 ? 0.0 : 0.1);
  }
  return calculation / 360 - (calculation / 360 > 0.6 ? 0.1 : 0.0);
};

export const getCoordinatesToPaint = (coordinates) => {
  if (coordinates.length === 6) {
    const Xmax = Math.max(
      coordinates[0][0],
      coordinates[1][0],
      coordinates[2][0],
      coordinates[3][0],
      coordinates[4][0],
      coordinates[5][0]
    );
    const Xmin = Math.min(
      coordinates[0][0],
      coordinates[1][0],
      coordinates[2][0],
      coordinates[3][0],
      coordinates[4][0],
      coordinates[5][0]
    );
    const Ymax = Math.max(
      coordinates[0][1],
      coordinates[1][1],
      coordinates[2][1],
      coordinates[3][1],
      coordinates[4][1],
      coordinates[5][1]
    );
    const Ymin = Math.min(
      coordinates[0][1],
      coordinates[1][1],
      coordinates[2][1],
      coordinates[3][1],
      coordinates[4][1],
      coordinates[5][1]
    );
    const yCoord = Math.min()
    return {
      catalogID: `${new Date().getTime()}`,
      xCoord: coordinates[0][0],
      yCoord: Ymin,
      height: Ymax - Ymin,
      width: Xmax - Xmin,
    };
  } else {
    const Xmax = Math.max(
      coordinates[0][0],
      coordinates[1][0],
      coordinates[2][0],
      coordinates[3][0]
    );
    const Xmin = Math.min(
      coordinates[0][0],
      coordinates[1][0],
      coordinates[2][0],
      coordinates[3][0]
    );
    const Ymax = Math.max(
      coordinates[0][1],
      coordinates[1][1],
      coordinates[2][1],
      coordinates[3][1]
    );
    const Ymin = Math.min(
      coordinates[0][1],
      coordinates[1][1],
      coordinates[2][1],
      coordinates[3][1]
    );
    return {
      catalogID: `${new Date().getTime()}`,
      xCoord: coordinates[0][0],
      yCoord: coordinates[0][1],
      height: Ymax - Ymin,
      width: Xmax - Xmin,
    };
  }
};