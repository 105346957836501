import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import beforeImage from "../../assets/images/before-slide.png";
import afterImage1 from "../../assets/images/carousel-images/1After.png";
import beforeImage1 from "../../assets/images/carousel-images/1Before.jpg";
import afterImage2 from "../../assets/images/carousel-images/2After.png";
import beforeImage2 from "../../assets/images/carousel-images/2Before.jpg";
import afterImage3 from "../../assets/images/carousel-images/3After.png";
import beforeImage3 from "../../assets/images/carousel-images/3Before.jpg";
import afterImage4 from "../../assets/images/carousel-images/4After.png";
import beforeImage4 from "../../assets/images/carousel-images/4Before.png";
import afterImage5 from "../../assets/images/carousel-images/5After.png";
import beforeImage5 from "../../assets/images/carousel-images/5Before.jpg";
import classes from "./style.module.css";

export default function BeforeAfterCarousel() {
  var settings = {
    dots: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className={`container ${classes.sliderContainer}`}>
      <div className="slider-container home-page-slider mt-4 px-0">
        <Slider {...settings}>
          <div className="position-relative">
            <img
              src={beforeImage1}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivBefore}>Before</div>
          </div>
          <div className="position-relative">
            <img
              src={afterImage1}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivAfter}>After</div>
          </div>
          <div className="position-relative">
            <img
              src={beforeImage2}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivBefore}>Before</div>
          </div>
          <div className="position-relative">
            <img
              src={afterImage2}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivAfter}>After</div>
          </div>
          <div className="position-relative">
            <img
              src={beforeImage3}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivBefore}>Before</div>
          </div>
          <div className="position-relative">
            <img
              src={afterImage3}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivAfter}>After</div>
          </div>
          <div className="position-relative">
            <img
              src={beforeImage4}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivBefore}>Before</div>
          </div>
          <div className="position-relative">
            <img
              src={afterImage4}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivAfter}>After</div>
          </div>
          <div className="position-relative">
            <img
              src={beforeImage5}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivBefore}>Before</div>
          </div>
          <div className="position-relative">
            <img
              src={afterImage5}
              alt="carousel slide"
              className={classes.slideImage}
            />
            <div className={classes.blurredDivAfter}>After</div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
