import { EDIT_ACTIONS } from "./actionTypes";
import * as _ from "lodash";
import { getCanvasImageData } from "../../utils";

export const getSelectedHouseImage = () => {
  return {
    type: EDIT_ACTIONS.GET_SELECTED_HOME_IMAGE,
  };
};

export const setSelectedCatalogDoor = (data) => {
  return async (dispatch, getStore) => {
    try {
      const doorType = _.get(data, "doorType", null);
      const homeStateData = getStore().home;
      const selectedType = _.get(
        _.get(homeStateData, "catalogData", {}),
        doorType,
        null
      );

      if (selectedType && selectedType.length) {
        dispatch({
          type: EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR,
          payload: selectedType[0].url_original,
        });
      } else {
        dispatch({
          type: EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR,
          payload: null,
        });
      }
    } catch (error) {
      // setLoading(false);
    }
  };
};

export const resetSelectedCatalogDoor = (payload) => {
  return {
    type: EDIT_ACTIONS.RESET_SELECTED_CATALOG_DOOR,
    payload: null,
  };
};

export const setSelectedCatalogDoorSuccess = (payload) => {
  return {
    type: EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR,
    payload,
  };
};

export const setSelectedCatalogDoorDimensions = (data) => {
  return async (dispatch) => {
    try {
      const { garageDoorData, shapeType } = data;
      const isCoordinatesToPaint = garageDoorData.filter(item => item.coordinatesToPaint);
      const coordinates = garageDoorData.map((item) => item.coordinates[0]);
      const dimensions =
      isCoordinatesToPaint && isCoordinatesToPaint.length
      ? garageDoorData.map((item) => item.coordinatesToPaint)
      : getCanvasImageData(coordinates);
        dispatch({
          type: EDIT_ACTIONS.SET_SELECTED_CATALOG_DOOR_DATA,
          payload: { garageDoorData, shapeType },
        });

        dispatch({
          type: EDIT_ACTIONS.SUCCESS_SET_SELECTED_CATALOG_DOOR_DIMENSIONS,
          payload: dimensions,
        });
    } catch (error) {
      // setLoading(false);
    }
  };
};

export const setIsTransformationHappened = (payload) => {
  return {
    type: EDIT_ACTIONS.SET_IS_TRANSFORMATION_HAPPENED,
    payload,
  };
};

export const setUpdateCatalogData = (payload) => {
  return {
    type: EDIT_ACTIONS.SET_SELECTED_CATALOG_DOOR_DATA,
    payload,
  };
};
