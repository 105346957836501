import { HOME_ACTIONS } from "./actionTypes";
import {
  getCatalogList,
  getFilterCatalogData,
  requestQuote,
} from "../../services/Home.service";
import { COMMON_ACTIONS } from "../common/actionTypes";
import { getDisplayClassID } from "../../utils";
import * as _ from "lodash";

export const getCatalogData = (url) => {
  return async (dispatch) => {
    let apiData = {};
    try {
      setLoading(true);
      apiData = await getCatalogList(url);
      setLoading(false);
      if (apiData) {
        const { one_car_garage, two_car_garage, front_door } = apiData.data;
        const filteredOneCarGarage = one_car_garage.filter(
          (item) => item.Deleted === 0 && item.typeID === 1
        );
        const filteredTwoCardGarage = two_car_garage.filter(
          (item) => item.Deleted === 0 && item.typeID === 2
        );
        const filteredFrontDoorSingle = front_door.filter(
          (item) => item.Deleted === 0 && item.typeID === 3
        );

        const filteredFrontDoorDouble = front_door.filter(
          (item) => item.Deleted === 0 && item.typeID === 4
        );
        const filteredFrontDoor = front_door.filter(
          (item) => item.Deleted === 0
        );

        const catalogPayload = {
          one_car_garage: filteredOneCarGarage,
          two_car_garage: filteredTwoCardGarage,
          front_door_single: filteredFrontDoorSingle,
          front_door_double: filteredFrontDoorDouble
        };

        dispatch({
          type: HOME_ACTIONS.SET_CATALOG_DATA,
          payload: catalogPayload,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
};

export const resetCatelogData = () => {
  return async (dispatch) => {
    setLoading(false);
    try {
      dispatch({
        type: HOME_ACTIONS.RESET_CATALOG_DATA,
        payload: null,
      });
    } catch (error) {
      setLoading(false);
    }
  }
}

export const getCatalogFilterData = (data, catalogURL) => {

  let filterVariables = {};
  const formatedData = data.split("&")
  formatedData.map((item) => {
    const key = item ? item.split("=")[0] : ""
    const value = item ? item.split("=")[1].replace("'", "") : "";
    filterVariables = { ...filterVariables, ...{ [key]: value.replace("'", "") } }
  })

  return async (dispatch, getStore) => {
    let apiData = {};
    try {
      setLoading(true);
      apiData = await getFilterCatalogData(catalogURL, data);
      setLoading(false);
      if (apiData) {
        const { one_car_garage, two_car_garage, front_door } = apiData.data;
        const filteredOneCarGarage = one_car_garage.filter(
          (item) => item.Deleted === 0 && item.typeID === 1
        );
        const filteredTwoCardGarage = two_car_garage.filter(
          (item) => item.Deleted === 0 && item.typeID === 2
        );
        const filteredFrontDoorSingle = front_door.filter(
          (item) => item.Deleted === 0 && item.typeID === 3
        );

        const filteredFrontDoorDouble = front_door.filter(
          (item) => item.Deleted === 0 && item.typeID === 4
        );
        const filteredFrontDoor = front_door.filter(
          (item) => item.Deleted === 0
        );

        const catalogPayload = {
          one_car_garage: filteredOneCarGarage,
          two_car_garage: filteredTwoCardGarage,
          front_door_single: filteredFrontDoorSingle,
          front_door_double: filteredFrontDoorDouble
        };

        dispatch({
          type: HOME_ACTIONS.SET_CATALOG_DATA,
          payload: catalogPayload,
        });

        dispatch({
          type: HOME_ACTIONS.SET_FILTER_VALUES,
          payload: filterVariables,
        });
      }
      // console.log(apiData.data);
      // const { one_car_garage, two_car_garage } = apiData.data;
      // const homeStateData = getStore().home;
      // const selectedCatalogID = homeStateData.selectedCatalogID || null;
      // if (apiData && selectedCatalogID) {
      //   dispatch({
      //     type: HOME_ACTIONS.SET_SELECTED_TYPE_DATA,
      //     payload: one_car_garage,
      //   });
      // }
    } catch (error) {
      setLoading(false);
    }
  };
};

export const setEditHouseImage = (payload) => {
  return {
    type: HOME_ACTIONS.SET_EDIT_HOUSE_IMAGE,
    payload,
  };
};

export const setSelectedDoordDetails = (payload, page) => {
  return async (dispatch, getStore) => {
    const homeData = getStore().home;
    let data = {}
    if (page === "garage") {
      data = {
        single: homeData.catalogData.one_car_garage.find(item => item.name === payload.name),
        double: homeData.catalogData.two_car_garage.find(item => item.name === payload.name)
      }
    } else {
      data = {
        single: homeData.catalogData.front_door_single.find(item => item.name === payload.name),
        double: homeData.catalogData.front_door_double.find(item => item.name === payload.name)
      }
    }
    dispatch({
      type: HOME_ACTIONS.SET_SELECTED_DOORS_DETAILS,
      payload: data
    });
  };
};

export const setSelectedTypeID = (data) => {
  return async (dispatch, getStore) => {
    const classIDs = _.get(data, "payload", []);
    const classID =
      classIDs.length > 1
        ? getDisplayClassID(classIDs)
        : classIDs.length
          ? classIDs[0]
          : null;
    // const classID = _.get(data, 'payload[0]', null);

    const homeStateData = getStore().home;
    const catalogList = homeStateData.catalogData || {};
    dispatch({
      type: HOME_ACTIONS.SET_SELECTED_TYPE_ID_ALL,
      payload: [...new Set(classIDs)],
    });

    dispatch({
      type: HOME_ACTIONS.SET_SELECTED_CATALOG_ID,
      payload: classID,
    });

    dispatch({
      type: HOME_ACTIONS.SET_SELECTED_TYPE_DATA,
      payload: catalogList.one_car_garage,
    });
  };
};

export const setSelectedCatalogID = (payload) => {
  return {
    type: HOME_ACTIONS.SET_SELECTED_CATALOG_ID,
    payload,
  };
};

export const sendEmail = (data, proposalURL) => {
  return async (dispatch, getStore) => {
    let apiData = {};
    try {
      setLoading(true);
      apiData = await requestQuote(data, proposalURL);

      const { email, userid } = _.get(apiData, "data", {});
      setLoading(false);

      if (email && userid) {
        setLoading(false);
        dispatch({
          type: COMMON_ACTIONS.SET_LOADER_VISIBILITY,
          payload: false,
        })

        dispatch({
          type: HOME_ACTIONS.SEND_EMAIL,
          payload: {
            visible: true,
            type: "success",
            message:
              "Thanks for the quote request. We will get back to you soon.",
          },
        });

        dispatch({
          type: COMMON_ACTIONS.SET_TOASTR_STATUS,
          payload: {
            visible: true,
            type: "success",
            message:
              "Thanks for the quote request. We will get back to you soon.",
          },
        });
      } else {
        dispatch({
          type: COMMON_ACTIONS.SET_LOADER_VISIBILITY,
          payload: false,
        })

        dispatch({
          type: COMMON_ACTIONS.SET_TOASTR_STATUS,
          payload: {
            visible: true,
            type: "error",
            message:
              "Something went wrong.You cannot proceed.",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      dispatch({
        type: COMMON_ACTIONS.SET_LOADER_VISIBILITY,
        payload: false,
      })

      dispatch({
        type: COMMON_ACTIONS.SET_TOASTR_STATUS,
        payload: {
          visible: true,
          type: "error",
          message:
            "Something went wrong.You cannot proceed.",
        },
      });
    }
  };
};

const setLoading = (loading) => {
  return async (dispatch) => {
    dispatch({
      type: COMMON_ACTIONS.SET_LOADER_VISIBILITY,
      payload: loading,
    });
  };
};
