import React, { useEffect, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import style from "./Interior.module.css";

const InteriorPreviewDetailsComponent = ({ selectedImage, onClose, handleDownloadClick }) => {
  const exitFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => console.error(err));
    } else if (document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
    } else if (document.mozFullScreenElement) {
      document.mozCancelFullScreen();
    } else if (document.msFullscreenElement) {
      document.msExitFullscreen();
    }
  }, []);

  const handleClose = useCallback(() => {
    exitFullscreen();
    onClose();
  }, [exitFullscreen, onClose]);

  const handleButtonClick = useCallback((action) => (e) => {
    e.stopPropagation();
    if (action === 'close') {
      handleClose();
    } else if (action === 'download') {
      handleDownloadClick(selectedImage.src);
    }
  }, [handleClose, handleDownloadClick, selectedImage]);

  useEffect(() => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen().catch((err) => console.error(err));
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    return () => {
      exitFullscreen();
    };
  }, [exitFullscreen]);

  return (
    <div className={style.fullScreenPreview}>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className={style.previewControls}>
              <button onClick={(e) => { e.stopPropagation(); zoomIn(e); }}>+</button>
              <button onClick={(e) => { e.stopPropagation(); zoomOut(e); }}>-</button>
              <button onClick={(e) => { e.stopPropagation(); resetTransform(e); }}>Reset</button>
              <button onClick={handleButtonClick('close')}>Close</button>
              <button onClick={handleButtonClick('download')}>Download</button>
            </div>
            <TransformComponent>
              <img
                src={selectedImage.src}
                alt="Detailed Preview"
                className={style.fullScreenImage}
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default InteriorPreviewDetailsComponent;