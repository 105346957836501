import { COMMON_ACTIONS } from "./actionTypes";

export const setLoaderVisibility = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_LOADER_VISIBILITY,
    payload,
  };
};

export const setToastrStatus = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_TOASTR_STATUS,
    payload,
  };
};

export const setScreenOrientationChange = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_SCREEN_ORIENTATION_CHANGE_DATA,
    payload,
  };
};

export const setBackStatus = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_BACK_STATUS,
    payload,
  };
};

export const setBackBtnVisibility = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_BACK_BTN_VISIBILITY,
    payload,
  };
};

export const setImageDimensions = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_IMAGE_DIMENSIONS,
    payload,
  };
};

export const setIsSampleHouse = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_IS_SAMPLE_HOUSE,
    payload,
  };
};

export const setExterierGeneratedImages = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_EXTERIOR_GENERATED_IMAGES,
    payload,
  };
};

export const setInteriorGeneratedImages = (payload) => {
  return {
    type: COMMON_ACTIONS.SET_INTERIOR_GENERATED_IMAGES,
    payload,
  };
};