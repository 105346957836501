import { getData, postData, uploadImage, get, post } from './Base.services';
import axios from 'axios'

export const getCatalogList = async (url) => {
    const response = await get(url);
    return response;
};

export const getFilters = async () => {
    const styles = getData('/GetCatalog/all_home_objects/styles');
    const price = getData('/GetCatalog/all_home_objects/price');
    const colors = getData('/GetCatalog/all_home_objects/colors');

    const response = await axios.all([
        styles,
        price,
        colors
    ]);

    return response;
}

export const uploadGarageHouseImage = async (image) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    var formData = new FormData();
    formData.append('image', image);
    formData.append('environment', 'dev');
    formData.append('app', 'web');
    const response = await uploadImage('/applyTrans', formData, config);
    return response;
};

export const uploadDoorHouseImage = async (image) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    var formData = new FormData();
    formData.append('image', image);
    formData.append('environment', 'dev');
    formData.append('app', 'web');
    const response = await uploadImage('/applyDoor', formData, config);
    return response;
};

export const getFilterCatalogData = async (catalogURL, queryParams) => {
    const response = await get(catalogURL.includes("?dealerName=") ? `${catalogURL}&${queryParams}` : `${catalogURL}?${queryParams}`);
    return response;
};

export const requestQuote = async (data, proposalURL) => {
    const { first_name, last_name, phoneno, zip, proposal_url, email } = data;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    var formData = new FormData();
    formData.append('email', email);
    formData.append('proposal_url', proposal_url);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('phoneno', phoneno);
    formData.append('zip', zip);
    const response = await post(proposalURL, formData, config);
    return response;
}

export const getEntryPoints = () => {
    return getData(`/entrypoint`);
}