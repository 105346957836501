import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import classes from "./Interior.module.css";
import uploadIcon from "../../assets/images/upload-image.png";

const InteriorUploadImgComponent = ({ onFileUpload }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  const handleFileClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="px-0" md={12}>
          <Card className="uploadImageCard">
            <Card.Body>
              <div onClick={handleFileClick}>
                <div className={classes.uploadImage}>
                  <img src={uploadIcon} alt="upload image icon" />
                  <p className="mt-3 mb-0">
                    Upload or drag image to preview your interior
                  </p>
                  <span className="text-muted">
                    Supports: PNG, JPG, JPEG, WEBP
                  </span>
                </div>
              </div>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                hidden
                onChange={handleFileUpload}
              />
              <Button
                variant="primary"
                className="mt-1 mt-sm-3"
                onClick={handleFileClick}
              >
                Choose File
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InteriorUploadImgComponent;