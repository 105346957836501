import { connect } from 'react-redux';
import HomeComponent from './Home.component.js';
import {
	resetCatelogData,
    
} from '../../redux/home/actions';
import {
    resetSelectedCatalogDoor
} from '../../redux/edit/actions';

const mapStateToProps = (state) => {
    return {
        selectedCatalogImageDimensions: state.edit.selectedCatalogImageDimensions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {                                        
        resetCatelogData: () => dispatch(resetCatelogData()),
        resetSelectedCatalogDoor: () => dispatch(resetSelectedCatalogDoor())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);