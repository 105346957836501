import { HOME_ACTIONS } from "./actionTypes";

/** Initial state for home reducer */
const initialState = {
  catalogData: {},
  selectedHouseImage: null,
  selectedTypeData: [],
  selectedTypeIDs: [],
  selectedCatalogID: null,
  selectedCatalogItemDetails: {},
  filterVariables:{}
};

/**
 * Function handing/responsible for updating home component's state
 * @param {object} state
 * @param {object} action
 */
export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_ACTIONS.SET_CATALOG_DATA:
      return {
        ...state,
        catalogData: action.payload,
        selectedTypeData: !state.selectedTypeData.length ? action.payload?.one_car_garage : state.selectedTypeData,
        filterVariables:{}
      };

      case HOME_ACTIONS.RESET_CATALOG_DATA:
      return {
        ...state,
        catalogData: {},
        selectedHouseImage: null, 
        selectedTypeData: [],
        selectedTypeIDs: [],
        selectedCatalogID: null,
        selectedCatalogItemDetails: {},
        selectedDoorsDetails:{},
        filterVariables:{}
      };
    case HOME_ACTIONS.SET_EDIT_HOUSE_IMAGE:
      return {
        ...state,
        selectedHouseImage: action.payload,
      };
    case HOME_ACTIONS.SET_SELECTED_TYPE_DATA:
      return {
        ...state,
        selectedTypeData: action.payload,
      };
    case HOME_ACTIONS.SET_SELECTED_TYPE_ID_ALL:
      return {
        ...state,
        selectedTypeIDs: action.payload,
      };
    case HOME_ACTIONS.SET_SELECTED_CATALOG_ID:
      return {
        ...state,
        selectedCatalogID: action.payload,
      };
    case HOME_ACTIONS.SET_SELECTED_CATALOG_ITEM_DETAILS:
      const { imgPath, url_original, name } = action.payload;
      return {
        ...state,
        selectedCatalogItemDetails: { imgPath, url_original, name },
      };

      case HOME_ACTIONS.SET_SELECTED_DOORS_DETAILS:
      return {
        ...state,
        selectedDoorsDetails: action.payload,
      };

    case HOME_ACTIONS.SET_FILTER_VALUES:
      return {
        ...state,
        filterVariables: action.payload,
      };
    default:
      return state;
  }
};
