import { connect } from 'react-redux';
import GarageComponent from './Garage.component';
import { getCatalogData, setSelectedTypeID, setSelectedCatalogID, getCatalogFilterData, sendEmail, setEditHouseImage, setSelectedDoordDetails, setFilterValues } from '../../redux/home/actions';
import { setLoaderVisibility, setToastrStatus, setScreenOrientationChange, setBackBtnVisibility, setIsSampleHouse } from '../../redux/common/actions';
import { setSelectedCatalogDoorSuccess, setSelectedCatalogDoorDimensions, resetSelectedCatalogDoor } from '../../redux/edit/actions';

const mapStateToProps = (state) => {
    return {
        catalogData: state.home.catalogData,
        filterVariables: state.home.filterVariables,
        selectedDoorsDetails: state.home.selectedDoorsDetails,
        selectedTypeData: state.home.selectedTypeData,
        selectedCatalogID: state.home.selectedCatalogID,
        selectedCatalogItemDetails: state.home.selectedCatalogItemDetails,
        isResponsiveView: state.common.isResponsiveView,
        isMobileOS: state.common.isMobileOS,
        isMobileLandscapeView: state.common.isMobileLandscapeView,
        isLandscape: state.common.isLandscape,
        selectedHouseImage: state.home.selectedHouseImage,
        selectedCatalogImage: state.edit.selectedCatalogImage,
        selectedCatalogImageDimensions: state.edit.selectedCatalogImageDimensions,
        selectedCatalogImageData: state.edit.selectedCatalogImageData,
        isToastrVisible: state.common.isToastrVisible,
        isSampleHouse: state.common.isSampleHouse,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIsSampleHouse: (payload) => dispatch(setIsSampleHouse(payload)),
        setBackBtnVisibility: (payload) => dispatch(setBackBtnVisibility(payload)),
        getCatalogData: (payload) => dispatch(getCatalogData(payload)),
        setSelectedTypeID: (payload) => dispatch(setSelectedTypeID(payload)),
        setSelectedCatalogID: (payload) => dispatch(setSelectedCatalogID(payload)),
        setLoaderVisibility: (payload) => dispatch(setLoaderVisibility(payload)),
        setToastrStatus: (payload) => dispatch(setToastrStatus(payload)),
        setScreenOrientationChange: (payload) => dispatch(setScreenOrientationChange(payload)),
        getCatalogFilterData: (payload, catalogURL) => dispatch(getCatalogFilterData(payload, catalogURL)),
        sendEmail: (payload, proposalURL) => dispatch(sendEmail(payload, proposalURL)),
        setEditHouseImage: (payload) => dispatch(setEditHouseImage(payload)),
        setSelectedCatalogDoor: (payload) => dispatch(setSelectedCatalogDoorSuccess(payload)),
        setSelectedCatalogDoorDimensions: (payload) => dispatch(setSelectedCatalogDoorDimensions(payload)),
        resetSelectedCatalogDoor: () => dispatch(resetSelectedCatalogDoor()),
        setSelectedDoordDetails: (payload) => dispatch(setSelectedDoordDetails(payload, "garage")),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GarageComponent);