import { connect } from 'react-redux';
import React from 'react';
import { setToastrStatus } from '../../../redux/common/actions';
import classes from './Toastr.module.css';

const mapStateToProps = (state) => {
    return {
        isToastrVisible: state.common.isToastrVisible,
        toastrMessage: state.common.toastrMessage,
        toastrType: state.common.toastrType
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setToastrStatus: (payload) => dispatch(setToastrStatus(payload))
    }
}

const ToastrComponent = ({ isToastrVisible = true, toastrMessage = 'Toastr error', toastrType = 'error', setToastrStatus }) => {
    const closeToastr = () => {
        setToastrStatus({
            visible: false,
            type: '',
            message: ''
        });
    }

    return (
        isToastrVisible && (
            <div className={classes.toastrContainer}>
                <div className={`${classes.toastrMessageContainer}`}>
                    <p>{toastrMessage}</p>
                    <div className={classes.close} onClick={closeToastr}>x</div>
                </div>
            </div>
        )
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastrComponent);

