import React, { useState, useEffect } from 'react';
import { Form, Accordion, Card, Modal, Button, ListGroup } from 'react-bootstrap';
import * as _ from 'lodash';
import WhatsAppIcon from '../../../assets/images/whatsapp.png';
import { getFilters } from '../../../services/Home.service';
import { PrimaryButton, SecondaryButton } from '../button/Button.component';
import { FormInput } from '../input/Input.component';
import classes from './Modal.module.css';
import { DOUBLE_DOOR, DOUBLE_DOOR_GARAGE, SINGLE_DOOR, SINGLE_DOOR_GARAGE } from '../../../constants/data';
import uplaodTipsImage from '../../../assets/images/tips-image.png'
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../redux/home/actions';
import ticIcon from '../../../assets/images/Tick.svg'
import cross from '../../../assets/images/Close.svg'

const initialFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    zip: ''
}

export const ConfirmationModal = React.memo(({ show, handleClose, uploadFile, message, confirmBtn }) => {
    return (
        <Modal show={show} onHide={() => {
            confirmBtn === "Upload" ?
                uploadFile(false) :
                handleClose(false)
        }}>
            {/* <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                {confirmBtn === "Upload" && <img className={classes.modalImage} src={uplaodTipsImage} />}
                {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
                <Button className={classes.modalBtn} onClick={() => handleClose ? handleClose(false) : uploadFile(true)}>
                    {confirmBtn}
                </Button>
            </Modal.Footer>
        </Modal>
    )
});

export const DeviceOSModal = React.memo(({ show, handleClose, isFrontDoor, setting }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            {
                !isFrontDoor ?
                    <Modal.Body>
                        The free Door Visualizer iOS app has more advanced features not available in the web version.
                        If you would like to try it out you can download it <a href={setting?.garage?.appLink} target="_blank" rel="noopener"> HERE </a>.
                        <br /><br />If you want to continue with the web version tap on "continue" below.
                    </Modal.Body> :
                    <Modal.Body>
                        The free ModifAI Front Door iOS app has new features not available in the web version (paint, shaped doors, angled doors,
                        before/after videos.) Download it <a href={setting?.door?.appLink} target="_blank" rel="noopener"> HERE </a>.
                        <br /><br />If you want to continue with the web version tap on "continue" below.
                    </Modal.Body>

            }
            <Modal.Footer>
                <Button className={classes.modalBtn} onClick={handleClose}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export const QuoteEmailModal = ({ show, handleClose, handleProposalSubmitClick = null }) => {
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState(initialFormValues);
    const [userDetails, setUserDetails] = useState(initialFormValues);
    const [isValidSubmit, setIsValidSubmit] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        // isValidForm();
        // isValidFormSubmit();
    }, [error]);

    const isValidFormSubmit = () => {
        setIsValidSubmit(true);
        const invalid = Object.values(error).filter(item => item);
        invalid.length && setIsValidSubmit(false);
    }

    const handleChange = (evt) => {
        const { id, value } = evt.target;
        setUserDetails({ ...userDetails, [id]: value });

        switch (id) {
            case 'firstName':
                if (value) {
                    setError({ ...error, firstName: '' });
                } else {
                    setError({ ...error, firstName: 'First name is required' });
                }
                return;
            case 'lastName':
                if (value) {
                    setError({ ...error, lastName: '' });
                } else {
                    setError({ ...error, lastName: 'Last name is required' });
                }
                return;
            case 'email':
                if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                    setError({ ...error, email: 'Enter valid email' });
                } else if (!value) {
                    setError({ ...error, email: 'Email is required' });
                } else {
                    setError({ ...error, email: '' });
                }
                return;
            case 'phoneNumber':
                if (value && value.length !== 10) {
                    setError({ ...error, phoneNumber: 'Enter valid phone number' });
                } else if (!value) {
                    setError({ ...error, phoneNumber: 'Phone number is required' });
                } else {
                    setError({ ...error, phoneNumber: '' });
                }
                return;
            case 'zip':
                if (value && value.length !== 5) {
                    setError({ ...error, zip: 'Enter valid zip' });
                } else if (!value) {
                    setError({ ...error, zip: 'Zip is required' });
                } else {
                    setError({ ...error, zip: '' });
                }
                return;
            default:
                return;
        }
    }

    const handleProposalSubmit = (evt) => {
        evt.preventDefault();
        const isValid = isValidForm();
        if (isValid) {
            handleProposalSubmitClick(userDetails);
            setUserDetails(initialFormValues);
            setIsFormSubmitted(true);
        }
    }

    const isValidForm = () => {
        const { firstName, lastName, email, phoneNumber, zip } = userDetails;
        const errors = {};
        if (!firstName) {
            errors.firstName = 'First name is required';
        } else {
            errors.firstName = '';
        }

        if (!lastName) {
            errors.lastName = 'Last name is required';
        } else {
            errors.lastName = '';
        }

        // if(!email) {
        //     errors.email = 'Email is required';
        // } else {
        //     errors.email = '';
        // }

        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Enter valid email';
        } else if (!email) {
            errors.email = 'Email is required';
        } else {
            errors.email = '';
        }

        // if(!phoneNumber) {
        //     errors.phoneNumber = 'Phone number is required';
        // } else {
        //     errors.phoneNumber = '';
        // }

        if (phoneNumber && phoneNumber.length !== 10) {
            errors.phoneNumber = 'Enter valid phone number';
        } else if (!phoneNumber) {
            errors.phoneNumber = 'Phone number is required';
        } else {
            errors.phoneNumber = '';
        }

        // if(!zip) {
        //     errors.zip = 'Zip is required';
        // } else {
        //     errors.zip = '';
        // }

        if (zip && zip.length !== 5) {
            errors.zip = 'Enter valid zip';
        } else if (!zip) {
            errors.zip = 'Zip is required';
        } else {
            errors.zip = '';
        }

        setError(errors);

        const isInvalid = Object.values(errors).filter(item => item);

        return isInvalid.length ? false : true;
    }

    const handleKeyPress = (evt) => {
        const { id, value } = evt.target;
        const theEvent = evt || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);

        const maxLength = (id === 'phoneNumber' ? 10 : 5);
        const regEx = /^[0-9\b]+$/;
        if (!regEx.test(key) || value.length === maxLength) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    return (
        <Modal show={show} onHide={handleClose} >
            <div className={classes.closeIconContainer}>
                <img src={cross} onClick={handleClose} className={classes.closeIcon} />
            </div>
                <Form onSubmit={handleProposalSubmit} className={classes.modalBody}>
                    <Modal.Body className={classes.modalContent}>
                        <div>Please enter your contact information so that we can have our local partner provide you details on pricing
                            and availability. You will receive a response by the next business day. Thanks
                        </div>
                        <div className={classes.quoteEmailBody}>
                            <FormInput placeholder='First Name *' ariaLabelValue='First name'
                                id='firstName'
                                value={userDetails.firstName} error={error.firstName} handleChange={handleChange} />
                            <FormInput placeholder='Last Name *' ariaLabelValue='First name'
                                id='lastName'
                                value={userDetails.lastName} error={error.lastName} handleChange={handleChange} />
                            <FormInput type="email" placeholder='Email *' ariaLabelValue='First name'
                                id='email'
                                value={userDetails.email} error={error.email} handleChange={handleChange} />
                            <div className={classes.phonezip}>
                                <FormInput placeholder='Phone Number *' ariaLabelValue='First name'
                                    id='phoneNumber'
                                    value={userDetails.phoneNumber} error={error.phoneNumber} handleChange={handleChange}
                                    handleKeyPress={handleKeyPress}
                                />
                                <FormInput placeholder='Zip *' ariaLabelValue='Zip'
                                    id='zip'
                                    value={userDetails.zip} error={error.zip} handleChange={handleChange}
                                    handleKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                        <div className={classes.quoteDisclaimer}>
                            Pricing info will be supplied by our local partner
                        </div>
                        <PrimaryButton type='submit' title='Request Proposal' />
                    </Modal.Body>
                </Form>
        </Modal>
    )
}

export const FilterModal = ({ show, handleClose, handleApply, setIsFilterModalActive, filterVariables }) => {
    const [styleList, setStyleList] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [colorList, setColorList] = useState([]);

    const [style, setStyle] = useState('');
    const [price, setPrice] = useState('');
    const [color, setColor] = useState('');
    useEffect(() => {
        getFilterList();
    }, []);

    const getFilterList = async () => {
        const list = await getFilters();
        const style = _.get(list, '[0].data', []);
        const price = _.get(list, '[1].data', []);
        const color = _.get(list, '[2].data', []);

        setStyleList(style);
        setPriceList(price);
        setColorList(color);
    }

    const setStyleValue = (value) => {
        setStyle(value);
    }

    const setPriceValue = (value) => {
        setPrice(value);
    }

    const setColorValue = (value) => {
        setColor(value);
    }

    const handleApplyClick = () => {
        if (style || price || color) {
            const styleParam = style && `style='${style}'&`;
            const priceParam = price && `price='${price}'&`;
            const colorParam = color && `color='${color}'`;
            const queryParam = `${styleParam}${priceParam}${colorParam}`;
            handleApply(queryParam);
            handleClose();
        }
    }

    const handleClearFilter = () => {
        handleApply('');
        handleClose();
        setStyle('');
        setPrice('');
        setColor('');
        setIsFilterModalActive(false)
    }
    useEffect(() => {
        setColor(filterVariables?.color || "");
        setStyle(filterVariables?.style || "");
        setPrice(filterVariables?.price || "");

        (color || style || price) && setIsFilterModalActive(true)
    }, [filterVariables])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className={classes.filterBody}>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header className={classes.filterHeader}>
                                <Accordion.Toggle as={Button} eventKey="0">
                                    Style
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ListGroup>
                                        {styleList.map(item => {
                                            return (
                                                <ListGroup.Item
                                                    key={item.style}
                                                    onClick={setStyleValue.bind(null, item.style)}
                                                    className={style === item.style && classes.selected}
                                                >{item.style}</ListGroup.Item>
                                            )
                                        })}
                                        {/* <ListGroup.Item 
                                            className={style === 'traditional' && classes.selected}
                                            onClick={setStyleValue.bind(null, 'traditional')}>Traditional</ListGroup.Item>
                                        <ListGroup.Item
                                            onClick={setStyleValue.bind(null, 'none')}
                                            className={style === 'none' && classes.selected}
                                        >None</ListGroup.Item>
                                        <ListGroup.Item
                                            className={style === 'contemporary' && classes.selected}
                                            onClick={setStyleValue.bind(null, 'contemporary')}
                                        >Contemporary</ListGroup.Item>
                                        <ListGroup.Item className={style === 'transitional' && classes.selected}
                                            onClick={setStyleValue.bind(null, 'transitional')}
                                            >Transitional</ListGroup.Item>
                                        <ListGroup.Item className={style === 'carriage' && classes.selected}
                                            onClick={setStyleValue.bind(null, 'carriage')}
                                        >Carriage</ListGroup.Item>
                                        <ListGroup.Item className={style === 'glass' && classes.selected}
                                            onClick={setStyleValue.bind(null, 'glass')}
                                        >Glass</ListGroup.Item> */}
                                    </ListGroup>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header className={classes.filterHeader}>
                                <Accordion.Toggle as={Button} eventKey="1">
                                    Price
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ListGroup>
                                        {priceList.map(item => (
                                            <ListGroup.Item
                                                key={item.price}
                                                className={price === item.price && classes.selected}
                                                onClick={setPriceValue.bind(null, item.price)}
                                            >{item.price}</ListGroup.Item>
                                        ))}
                                        {/* <ListGroup.Item
                                            className={price === '$' && classes.selected}
                                            onClick={setPriceValue.bind(null, '$')}
                                        >$</ListGroup.Item>
                                        <ListGroup.Item
                                            className={price === '$$' && classes.selected}
                                            onClick={setPriceValue.bind(null, '$$')}
                                        >$$</ListGroup.Item>
                                        <ListGroup.Item
                                            className={price === '$$$' && classes.selected}
                                            onClick={setPriceValue.bind(null, '$$$')}
                                        >$$$</ListGroup.Item> */}
                                    </ListGroup>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header className={classes.filterHeader}>
                                <Accordion.Toggle as={Button} eventKey="2">
                                    Color
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <ListGroup>
                                        {/* <ListGroup.Item
                                            className={color === '' && classes.selected}
                                            onClick={setColorValue.bind(null, '')}
                                        >None</ListGroup.Item> */}
                                        {colorList.map(item => (
                                            <ListGroup.Item
                                                key={item.color}
                                                className={color === item.color && classes.selected}
                                                onClick={setColorValue.bind(null, item.color)}
                                            >{item.color}</ListGroup.Item>
                                        ))}
                                        {/* <ListGroup.Item
                                            className={color === 'brown' && classes.selected}
                                            onClick={setColorValue.bind(null, 'brown')}
                                        >Brown</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'white' && classes.selected}
                                            onClick={setColorValue.bind(null, 'white')}
                                        >White</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'grey' && classes.selected}
                                            onClick={setColorValue.bind(null, 'grey')}
                                        >Grey</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'black' && classes.selected}
                                            onClick={setColorValue.bind(null, 'black')}
                                        >Black</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'blue' && classes.selected}
                                            onClick={setColorValue.bind(null, 'blue')}
                                        >Blue</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'red' && classes.selected}
                                            onClick={setColorValue.bind(null, 'red')}
                                        >Red</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'green' && classes.selected}
                                            onClick={setColorValue.bind(null, 'green')}
                                        >Green</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'copper' && classes.selected}
                                            onClick={setColorValue.bind(null, 'copper')}
                                        >Copper</ListGroup.Item>
                                        <ListGroup.Item
                                            className={color === 'gold' && classes.selected}
                                            onClick={setColorValue.bind(null, 'gold')}
                                        >Gold</ListGroup.Item> */}
                                    </ListGroup>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton title='Clear' handleClick={handleClearFilter} />
                <PrimaryButton title='Apply' handleClick={handleApplyClick} />
            </Modal.Footer>
        </Modal>
    )
}

export const ShareComponent = ({ show, handleClose }) => {
    const handleShareClick = () => {
        // console.log('');
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className={classes.shareBodyContainer}>
                    <img src={WhatsAppIcon} className={classes.shareItem} onClick={handleShareClick} />
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button className={classes.modalBtn} onClick={handleClose}>
                Continue
            </Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export const EditAddDoorImageComponent = ({ show, handleClose, page }) => {
    const [doorType, setDoorType] = useState('');
    const [doorStyle, setDoorStyle] = useState('rectangle');

    const handleDoorTypeChange = (evt) => {
        setDoorType(evt.target.value);
    }

    const handleDoorStyleChange = (evt) => {
        setDoorStyle(evt.target.value);
    }

    const handleApplyClick = () => {
        handleClose({ doorType, doorStyle });
    }

    return (
        <Modal show={show} onHide={() => handleClose(false)}>
            <Modal.Body>
                <div className={classes.editAddDoorContainer}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Door Type</Form.Label>
                        <Form.Control as="select" onChange={handleDoorTypeChange}>
                            <option value="">Select door type</option>
                            {
                                page === "/edit-garage" ?
                                    <>
                                        <option value={DOUBLE_DOOR_GARAGE}>Two car garage</option>
                                        <option value={SINGLE_DOOR_GARAGE}>One car garage</option>
                                    </> :
                                    <>
                                        <option value={DOUBLE_DOOR}>Double frontdoor</option>
                                        <option value={SINGLE_DOOR}>Single frontdoor</option>
                                    </>
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Door Style</Form.Label>
                        <Form.Control as="select" onChange={handleDoorStyleChange} value={doorStyle}>
                            {
                                page === "/edit-garage" ?
                                    <>
                                        <option value="">Select door style</option>
                                        <option value="arc">Arched</option>
                                        <option value="rectangle">Rectangular</option>
                                        <option value="dutch_corner">Dutch corner</option>
                                    </> :
                                    <option value="rectangle">Rectangular</option>
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton title="Apply" handleClick={handleApplyClick} />
                {/* <Button className={classes.modalBtn} onClick={handleClose}>
                Continue
            </Button> */}
            </Modal.Footer>
        </Modal>
    );
}

export const QuoteEmailSuccessModal = ({ show, handleClose}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Body className={classes.SuccessModalBody}>
        <div className={classes.closeIconContainer}>
          <img
            src={cross}
            onClick={handleClose}
            className={classes.closeIcon}
          />
        </div>
        <div>
          <img src={ticIcon} />
        </div>
        <p>Your Request has been sent Successfully!</p>
      </Modal.Body>
    </Modal>
  );
};