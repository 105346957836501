export const COMMON_ACTIONS = {
  SET_LOADER_VISIBILITY: "SET_LOADER_VISIBILITY",
  SET_TOASTR_STATUS: "SET_TOASTR_STATUS",
  SET_SCREEN_ORIENTATION_CHANGE_DATA: "SET_SCREEN_ORIENTATION_CHANGE_DATA",
  SET_BACK_STATUS: "SET_BACK_STATUS",
  SET_BACK_BTN_VISIBILITY: "SET_BACK_BTN_VISIBILITY",

  SET_IMAGE_DIMENSIONS: "SET_IMAGE_DIMENSIONS",
  SET_IS_SAMPLE_HOUSE: "SET_IS_SAMPLE_HOUSE",
  SET_EXTERIOR_GENERATED_IMAGES: "SET_EXTERIOR_GENERATED_IMAGES",
  SET_INTERIOR_GENERATED_IMAGES: "SET_INTERIOR_GENERATED_IMAGES"
};
