// import React from 'react';
// import EmailIcon from '../../../assets/images/email.png';
// import classes from './Contact.module.css';

// const Contact = React.memo(({ isMobileLandscape, isMobileOS, isIOSDevice, handleEmailClick, isFrontDoor, setting }) => {
//     const handleDisclaimerClick = () => {
//         if (isFrontDoor) {
//             window.open(`${setting?.door?.appLink}`, '_blank');
//         } else {
//             window.open(`${setting?.garage?.appLink}`, '_blank');
//         }
//     }
//     const productName = isFrontDoor ? setting?.door.appName : setting?.garage.appName;

//     return (
//         isMobileLandscape ? (
//             <div className={classes.mobileLandscapeContainer}>
//                 <div className={`${classes.contactContainer} ${classes.contactContainerLandscape} ${isMobileLandscape && classes.mobileContact}`}>
//                     <div className={classes.contactContainerTxt}>Email us for a free quote&#8594;</div>
//                     <img src={EmailIcon} onClick={handleEmailClick} />
//                 </div>
//                 {isIOSDevice && (
//                     <div className={classes.downloadLink} onClick={handleDisclaimerClick}>
//                         Download the {productName} app for the full version with more features
//                     </div>
//                 )}
//                 <div className={`${classes.disclaimerContainer} ${classes.disclaimerContainerLandscape}`}>** Doors are meant as visualizations and are only representative. Actual doors may look differently when on
//                     your home with natural lighting. Please discuss actual materials and colors with your dealer before.
//                 </div>
//             </div>
//         ) : (
//             <div className={`${classes.contactContainerWrapper} ${isMobileOS ? 'mobileContactWrapper' : 'desktopContactWrapper'}`}>
//                 <div className={!isMobileOS && 'contactContainer'}>
//                     <div className={`${classes.contactContainer} ${!isMobileOS && 'contact'}`}>
//                         <div className={classes.contactContainerTxt}>Email us for a free quote &#8594;</div>
//                         <img src={EmailIcon} onClick={handleEmailClick} />
//                     </div>
//                     {isIOSDevice && (
//                         <div className={classes.downloadLink} onClick={handleDisclaimerClick}>
//                             Download the {productName} app for the full version with more features
//                         </div>
//                     )}
//                     <div className={classes.disclaimerContainer}>
//                         <strong>Disclaimer</strong>: Doors shown in {productName} are visualzations and may not accurately reflect the look of the new
//                         door on your home. Please talk to your dealer about styles, colors and textures before ordering. Always get samples to test before purchasing.
//                     </div>
//                 </div>
//             </div>
//         )
//     )
// })

// export default Contact;






import React from 'react';
import EmailIcon from '../../../assets/images/Email.svg';
import classes from './Contact.module.css';

const Contact = React.memo(({ isMobileLandscape, isMobileOS, isIOSDevice, handleEmailClick, isFrontDoor, setting }) => {
    const handleDisclaimerClick = () => {
        if (isFrontDoor) {
            window.open(`${setting?.door?.appLink}`, '_blank');
        } else {
            window.open(`${setting?.garage?.appLink}`, '_blank');
        }
    }
    const productName = isFrontDoor ? setting?.door.appName : setting?.garage.appName;

    return (
        isMobileLandscape ? (
            <div className={classes.mobileLandscapeContainer}>
                <div className={`${classes.contactContainer} ${classes.contactContainerLandscape} ${isMobileLandscape && classes.mobileContact}`}>
                    <div className={classes.leftContainer}>
                        {isIOSDevice && (
                            <div className={classes.downloadLink} onClick={handleDisclaimerClick}>
                                Download the {productName} app for the full version with more features
                            </div>
                        )}
                        <div className={`${classes.disclaimerContainer} ${classes.disclaimerContainerLandscape}`}>
                            ** Doors are meant as visualizations and are only representative. Actual doors may look differently when on
                            your home with natural lighting. Please discuss actual materials and colors with your dealer before.
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        <img src={EmailIcon} onClick={handleEmailClick} />
                        <div className={classes.contactContainerTxt}>Email us for a free quote</div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={`${classes.contactContainerWrapper} ${isMobileOS ? 'mobileContactWrapper' : 'desktopContactWrapper'}`}>
                <div className={!isMobileOS && 'contactContainer'}>
                    <div className={classes.contactContainer}>
                        <div className={classes.leftContainer}>
                            {isIOSDevice && (
                                <div className={classes.downloadLink} onClick={handleDisclaimerClick}>
                                    Download the {productName} app for the full version with more features
                                </div>
                            )}
                            <div className={classes.disclaimerContainer}>
                                <strong>Disclaimer</strong>: Doors shown in {productName} are visualizations and may not accurately reflect the look of the new
                                door on your home. Please talk to your dealer about styles, colors and textures before ordering. Always get samples to test before purchasing.
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.contactContainerTxt}>Email us for a free quote&#8594;</div>
                            <img src={EmailIcon} onClick={handleEmailClick} />
                        </div>
                    </div>
                </div>
            </div>
        )
    )
})

export default Contact;
